<script setup>
	import { computed, ref, watch ,onBeforeMount, onMounted} from "vue"
	import { XIcon } from "@heroicons/vue/outline"
	import { TabPanel } from "@headlessui/vue"
	import { useStore } from "vuex"
	import _ from "lodash"
	
	import FormFieldOptionTextModal from '@/modals/form/FormFieldOptionText.vue';

	import FieldDetailBase from '$features/organisms/Forms/FieldDetail/Base.vue'
	import FieldDetailRules from '$features/organisms/Forms/FieldDetail/Rules.vue'
	import FieldDetailOptions from '$features/organisms/Forms/FieldDetail/Options.vue'
	import FieldDetailRoomMates from '$features/organisms/Forms/FieldDetail/RoomMates.vue'
	import FieldDetailFlight from '$features/organisms/Forms/FieldDetail/Flight.vue'
	import FieldDetailContracts from '$features/organisms/Forms/FieldDetail/Contracts.vue'
	import FieldDetailFileUpload from '$features/organisms/Forms/FieldDetail/FileUpload.vue'
	import FieldDetailChoiceMatrix from '$features/organisms/Forms/FieldDetail/ChoiceMatrix.vue'
	

	const store = useStore()
	const emit = defineEmits(["hide"])

	// PROPS & EMITS
	const props = defineProps({
		field_id: Number,
	})

	store.commit('form/setActiveField',props.field_id)

	const selected_option =ref(null)
	const field = ref({})
	const active_field = computed(() => store.state.form.active_field)
	
	watch(() => props.field_id, data => {
		store.commit('form/setActiveField',data)
	})

	const tab_titles = computed(() => {
		let tabs = ["Alan Bilgileri", "Kurallar"]
		if (["dropdown", "checkbox", "checkboxes", "radio", "flight","choice_matrix","multiple_choice"].includes(active_field.value.type)) {
			tabs.push("Seçenekler")
		} else if (["room_mates","fileupload"].includes(active_field.value.type)) {
			tabs.push("Ayarlar")
		} else if (["contracts"].includes(active_field.value.type)) {	
			tabs.push("Sözleşmeler")
		}
		return tabs
	})

	const hide = () => {
		store.commit('form/setActiveField',null)
		emit("hide")
	}

	const form_field_option_close = () =>{
		isFormFiedlOptionModalOpen.value = false;
		selected_option.value = null
	}

</script>

<template>
	<div class="text-xl font-light mb-2 uppercase p-4 flex">
		<span>Alan Bilgilerini Güncelle</span>
		<div
			class="rounded-full w-7 h-7 ml-auto p-1 hover:bg-gray-400 cursor-pointer hover:bg-opacity-10"
			@click="hide"
		>
			<XIcon />
		</div>
	</div>
	
	<div>{{ active_field.title }}</div>
	<div>{{ active_field.type }}</div>
	<UITabs :key="field.id" :titles="tab_titles" class="w-full px-4 pb-16">
		
		<TabPanel key="1" class="py-4">
			<FieldDetailBase/>
		</TabPanel>
		<TabPanel key="2" class="py-4">
			<FieldDetailRules/>
		</TabPanel>
		<TabPanel key="3" class="py-4" v-if="['dropdown', 'checkbox','checkboxes','radio','multiple_choice'].includes(active_field.type) && !['room_mates', 'flight','contracts','fileupload'].includes(active_field.type)" >
			<FieldDetailOptions/>
		</TabPanel>
		<TabPanel key="4" class="py-4" v-if="['room_mates'].includes(active_field.type)">
			<FieldDetailRoomMates/>
		</TabPanel>
		<TabPanel key="5" class="py-4" v-if="['flight'].includes(active_field.type)" >
			<FieldDetailFlight/>
		</TabPanel>
		<TabPanel key="3" class="py-4" v-if="['contracts'].includes(active_field.type)" >
			<FieldDetailContracts/>
		</TabPanel>
		<TabPanel key="4" class="py-4" v-if="['fileupload'].includes(active_field.type)">
			<FieldDetailFileUpload/>
		</TabPanel>
		<TabPanel key="4" class="py-4" v-if="['choice_matrix'].includes(active_field.type)">
			<FieldDetailChoiceMatrix/>
		</TabPanel>
	</UITabs>

	<FormFieldOptionTextModal v-if="selected_option" :isOpen="isFormFiedlOptionModalOpen" :option="selected_option" @modal_closed="form_field_option_close"/>
</template>
