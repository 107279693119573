<script setup>

</script>

<template>
    <UIFormRow>
				<UISelect class="col-span-2" :source="[{value:'image',text:'Görseller'},{value:'file',text:'Döküman'}]" label="Dosya Tipi Seçiniz" title="Dosya Tipi" display="box"  v-model="field.file_type"/>
			</UIFormRow>
			<hr class="my-5" />
			<UIFormRow>
				<UIInput type="number" class="text-xs font-medium text-gray-500 mb-1" label="Max dosya büyüklüğü (MB Cinsinden) " v-model="field.file_size" />
			</UIFormRow>    
</template>