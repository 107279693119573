<script setup>
    import { computed, ref, watch ,onBeforeMount, onMounted, provide} from "vue"
    import API from "@/api"
    import { useStore } from "vuex"
    import _ from 'lodash'
    import { L10n,setCulture } from '@syncfusion/ej2-base';
    import { RichTextEditorComponent as EjsRichtexteditor,Toolbar,HtmlEditor } from "@syncfusion/ej2-vue-richtexteditor";
    

    const store = useStore();

    const richtexteditor =  [Toolbar, HtmlEditor];
    const rteInstance = ref()
    provide('richtexteditor', richtexteditor);
    setCulture("tr");

    const form_data = computed(() => store.state.form.active_form)
    const active_field = computed(() => store.state.form.active_field)
    const field = ref({})
    const db_field_columns = ref([])

    const items = {
        "heading":{
            title_color : false,
            other_settings : false,
            default_value : false,
            editor : true,
            title : false,
            placeholder : false,
            helptext : false
        },
        "paragraph":{
            title_color:false,
            other_settings : false,
            default_value : false,
            editor : true,
            title : false,
            placeholder : false,
            helptext : false
        },
        "banner":{
            title_color:false,
            other_settings : false,
            default_value : false,
            editor : true,
            title : false,
            placeholder : false,
            helptext : false
        },
        "dropdown":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "tr_cities":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "picture_choice":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "multiselect":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "switch":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "multiple_choice":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "checkbox":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "checkboxes":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "choice_matrix":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "date_picker":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "date_time_picker":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "time_picker":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "date_range":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "ranking":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "star_rating":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "slider":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "opinion_scale":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "tckimlik":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "short_answer":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "long_answer":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "email_input":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "phone_number":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "address":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : false,
            placeholder : false,
            helptext : false
        },
        "number":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "currency":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "url_input":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "color_picker":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "password":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : true,
            helptext : true
        },
        "file_uploader":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "signature":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "voice_recording":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "captcha":{
            title_color:false,
            other_settings : false,
            default_value : false,
            editor : false,
            title : false,
            placeholder : false,
            helptext : false
        },
        "location_coordinates":{
            title_color:true,
            other_settings : true,
            default_value : true,
            editor : false,
            title : true,
            placeholder : false,
            helptext : true
        },
        "image":{
            title_color:true,
            other_settings : false,
            default_value : false,
            editor : false,
            title : true,
            placeholder : false,
            helptext : false
        },
        "video":{
            title_color:true,
            other_settings : false,
            default_value : false,
            editor : false,
            title : true,
            placeholder : false,
            helptext : false
        },
        "pdf_viewer":{
            title_color:true,
            other_settings : false,
            default_value : false,
            editor : false,
            title : true,
            placeholder : false,
            helptext : false
        },
        "divider":{
            title_color:false,
            other_settings : false,
            default_value : false,
            editor : false,
            title : false,
            placeholder : false,
            helptext : false
        },
        "html":{
            title_color:true,
            other_settings : false,
            default_value : false,
            editor : false,
            title : true,
            placeholder : false,
            helptext : false
        }
    }
    
    const has_feature = (item, feature) => {
        return items[item][feature]
    }

    const banner_types = ref([
        { value: "info", text: "Bilgi" },
        { value: "warning", text: "Uyarı" },
        { value: "error", text: "Hata" },
        { value: "success", text: "Başarı" },
    ])

    watch(() => active_field.value, data => {
        field.value = _.cloneDeep(active_field.value)
        console.log(field.value)
    },{ immediate: true,deep:true })
    
    watch(() => field.value, data => {
        if(_.isEqual(field.value, active_field.value)) return

        store.commit('form/setFieldData',{id:active_field.value.id,field:data})
        API.saveFormField(field.value.id, data)
			.then( response => {
				store.dispatch("form/updateField", {
					id: field.value.id,
					field: data,
				})
				// store.dispatch("floating_alerts/addAlert", {
				// 	type: "tip",
				// 	title: "BAŞARILI!",
				// 	description: response.data.message,
				// })
			})
			.catch( error => {
				store.dispatch("floating_alerts/addAlert", {
					type: "danger",
					title: "HATA!",
					description: error.response.data.message,
				})
			})
    },{ immediate: true,deep:true })

    
    onMounted(() => {
        
    })

    const field_default_value_options = computed(() => {
		if (
			form_data.value.form_type == "mma" ||
			form_data.value.type == "PUBLIC"
		) {
			return [
				{ value: "NONE", text: "Yok" },
				{ value: "FIXED", text: "Sabit" },
			]
		} else {
			return [
				{ value: "NONE", text: "Yok" },
				{ value: "FIXED", text: "Sabit" },
				{ value: "DB", text: "Veritabanı Alanı" },
			]
		}
	})

    const onSpecChange = e => {
		field.value[e.value] = e.checked ? 1 : 0
	}

    const db_column_fields = computed(() => {
		
		let f = []
		if (Array.isArray(db_field_columns.value)) {
			db_field_columns.value.map( arr =>
				f.push({
					value: arr.key,
					text: arr.text,
					subtext: "DB Alanı",
				})
			)
		}

		return f
	})

    const getDbColumns = () => {
		API.get("project/"+ form_data.value.project_id +"/attendees/column")
        .then( response => {
			//db_field_columns.value = response.data == {} ? [] : response.data
		})
	}

    if(form_data.value.project_id) {
		getDbColumns()
	}

    const toolbarSettings = {
            type: 'Expand',
            items: [
                'Formats', 'Alignments', '|', 
                'Bold', 'Italic', 'Underline',
                'FontSize', 'FontColor'
            ]
        }
    
    
    L10n.load({
    'tr': {
        'richtexteditor': {
            "alignments": "Hizalamalar",
            "justifyLeft": "Metni sola hizala",
            "justifyCenter": "Metni ortala",
            "justifyRight": "Metni sağa hizala",
            "justifyFull": "İki yana yasla",
            "fontName": "Yazı tipi ailesini seçin",
            "fontSize": "Yazı tipi boyutunu seçin",
            "fontColor": "Renk seçin",
            "backgroundColor": "Arka plan rengi",
            "bold": "Kalın",
            "italic": "İtalik",
            "underline": "Altını çiz",
            "strikethrough": "Üstü çizili",
            "clearAll": "Hepsini temizle",
            "clearFormat": "Biçimi temizle",
            "cut": "Kes",
            "copy": "Kopyala",
            "paste": "Yapıştır",
            "unorderedList": "Sırasız liste ekle",
            "orderedList": "Sıralı liste ekle",
            "indent": "Girinti",
            "outdent": "Çıkıntı",
            "undo": "Geri al",
            "redo": "Yinele",
            "superscript": "Üst simge",
            "subscript": "Alt simge",
            "createLink": "Bağlantı ekle",
            "removeLink": "Bağlantıyı kaldır",
            "openLink": "Bağlantıyı aç",
            "editLink": "Bağlantıyı düzenle",
            "image": "Resim ekle",
            "replace": "Değiştir",
            "align": "hizala",
            "caption": "Resim yazısı",
            "formats": "Biçimler",
            "remove": "Sil",
            "insertLink": "Bağlantı ekle",
            "display": "Görüntüle",
            "alttext": "Alternatif metin",
            "dimension": "Boyut",
            "fullscreen": "Tam ekran",
            "maximize": "Büyüt",
            "minimize": "Küçült",
            "zoomIn": "Yakınlaştır",
            "zoomOut": "Uzaklaştır",
            "upperCase": "Büyük harf",
            "lowerCase": "Küçük harf",
            "print": "Yazdır",
            "sourcecode": "Kaynak kodu",
            "preview": "Önizleme",
            "viewside": "Yan görünüm",
            "insertcode": "Kod ekle",
            "linkText": "Bağlantı metni",
            "linkTooltipLabel": "Araç ipucu",
            "linkWebUrl": "Web adresi",
            "linkOpenInNewWindow": "Bağlantıyı yeni pencerede aç",
            "linkHeader": "Bağlantı ekle",
            "dialogInsert": "Ekle",
            "dialogCancel": "İptal",
            "dialogUpdate": "Güncelle",
            "imageHeader": "Resim ekle",
            "imageLinkHeader": "İnternet bağlantısı ekleyin",
            "imageUploadMessage": "Resmi buraya sürükleyin veya yüklemek için tıklayın",
            "imageDeviceUploadMessage": "Yüklemek için buraya tıklayın",
            "imageAlternateText": "Alternatif metin",
            "alternateHeader": "Alternatif metin",
            "browse": "Gözat",
            "imageUrl": "URL",
            "imageCaption": "Alt yazı",
            "imageSizeHeader": "Resim boyutu",
            "imageHeight": "Yükseklik",
            "imageWidth": "Genişlik",
            "textPlaceholder": "Metin girin",
            "inserttablebtn": "Tablo ekle",
            "tabledialogHeader": "Tablo ekle",
            "tableWidth": "Genişlik",
            "cellpadding": "Hücre dolgusu",
            "cellspacing": "Hücre aralığı",
            "columns": "Sütun sayısı",
            "rows": "Satır sayısı",
            "tableRows": "Tablo satırları",
            "tableColumns": "Tablo sütunları",
            "tableCellHorizontalAlign": "Hücre yatay hizalama",
            "tableCellVerticalAlign": "Hücre dikey hizalama",
            "createTable": "Tablo oluştur",
            "removeTable": "Tabloyu kaldır",
            "tableHeader": "Tablo başlığı",
            "tableRemove": "Tabloyu kaldır",
            "tableCellBackground": "Hücre arka planı",
            "tableEditProperties": "Tablo özelliklerini düzenle",
            "styles": "Stiller",
            "insertColumnLeft": "Sola sütun ekle",
            "insertColumnRight": "Sağa sütun ekle",
            "deleteColumn": "Sütunu sil",
            "insertRowBefore": "Önce satır ekle",
            "insertRowAfter": "Sonra satır ekle",
            "deleteRow": "Satırı sil",
            "tableEditHeader": "Tabloyu düzenle",
            "TableHeadingText": "Başlık",
            "TableColText": "Sütun",
            "imageInsertLinkHeader": "Bağlantı ekle",
            "editImageHeader": "Resmi düzenle",
            "alignmentsDropDownLeft": "Sola hizala",
            "alignmentsDropDownCenter": "Ortaya hizala",
            "alignmentsDropDownRight": "Sağa hizala",
            "alignmentsDropDownJustify": "İki yana yasla",
            "imageDisplayDropDownInline": "Satır içi",
            "imageDisplayDropDownBreak": "Kır",
            "tableInsertRowDropDownBefore": "Önce satır ekle",
            "tableInsertRowDropDownAfter": "Sonra satır ekle",
            "tableInsertRowDropDownDelete": "Satırı sil",
            "tableInsertColumnDropDownLeft": "Sola sütun ekle",
            "tableInsertColumnDropDownRight": "Sağa sütun ekle",
            "tableInsertColumnDropDownDelete": "Sütunu sil",
            "tableVerticalAlignDropDownTop": "Üst hizalama",
            "tableVerticalAlignDropDownMiddle": "Orta hizalama",
            "tableVerticalAlignDropDownBottom": "Alt hizalama",
            "tableStylesDropDownDashedBorder": "Kesikli sınırlar",
            "tableStylesDropDownAlternateRows": "Alternatif satırlar",
            "pasteFormat": "Biçimi yapıştır",
            "pasteFormatContent": "Biçimlendirme eylemini seçin",
            "plainText": "Düz metin",
            "cleanFormat": "Temizle",
            "keepFormat": "Biçimi koru",
            "formatsDropDownParagraph": "Paragraf",
            "formatsDropDownCode": "Kod",
            "formatsDropDownQuotation": "Alıntı",
            "formatsDropDownHeading1": "Başlık 1",
            "formatsDropDownHeading2": "Başlık 2",
            "formatsDropDownHeading3": "Başlık 3",
            "formatsDropDownHeading4": "Başlık 4",
            "fontNameSegoeUI": "Segoe UI",
            "fontNameArial": "Arial",
            "fontNameGeorgia": "Georgia",
            "fontNameImpact": "Impact",
            "fontNameTahoma": "Tahoma",
            "fontNameTimesNewRoman": "Times New Roman",
            "fontNameVerdana": "Verdana"
        }
    }
});

    
</script>

<template>
    <UIFormRow v-if="has_feature(field.type,'editor')">
        <ejs-richtexteditor ref="rteInstance" v-model="field.props.content" :toolbarSettings="toolbarSettings"/>
    </UIFormRow>
    <UIFormRow v-if="has_feature(field.type,'title')">
        <UITextarea
            label="Alan Başlığı"
            lazy
            v-model="field.title"
            :maxlength="300"
            :autogrow="false"
        />
    </UIFormRow>
    
    <UIFormRow v-if="has_feature(field.type,'placeholder')">
        <UIInput 
            label="Placeholder" 
            lazy 
            v-model="field.placeholder" 
            help_text="Placeholder metni alana girilmesi istenen bilgi ile ilgili açıklayıcı kısa ipucudur. İpucu alanda görünür ve odaklanıldığında kaybolur veya pasifleşir."
        />
    </UIFormRow>
    <UIFormRow v-if="has_feature(field.type,'helptext')">
        <UIInput
            label="Yardım Metni"
            lazy
            v-model="field.helptext" 
            help_text="Yardım metni, alanın hemen altında daha küçük ve daha soluk bir yazı olarak görüntülenir."
        />
    </UIFormRow>


    <UIFormRow v-if="field.type == 'not'">
        <UITextarea
            class="h-60"
            label="Açıklama"
            lazy
            v-model="field.data"
            :autogrow="false"
        />
    </UIFormRow>
    


    <UIFormRow v-if="field.type == 'image'">
        <UIFileInput
            label="Görsel Yükle"
            class="grow"
            v-model="field.props.url"
        />
    </UIFormRow>

    <UIFormRow v-if="field.type == 'banner'">
        <UISelect
            display="box"
            lazy
            :source="banner_types"
            label="Banner Tipi"
            v-model="field.props.type"
        />
    </UIFormRow>

    <UIFormRow v-if="field.type == 'video'">
        <UIInput 
            label="Youtube Video URL" 
            lazy 
            v-model="field.props.url" 
            help_text=""
        />
    </UIFormRow>
    <UIFormRow class="flex" v-if="['star_rating'].includes(field.type)">
        <div class="w-1/2">
            <label class="font-bold text-sm mb-1 block">Yıldız Sayısı</label>
            <div class="flex">
                <div class="w-1/2 mr-2">
                    <UIInput
                        lazy
                        label_class="text-gray-700 font-normal text-xs"
                        v-model="field.props.no_of_stars"
                    />
                </div>
            </div>
        </div>
        <div class="w-1/2"></div>
    </UIFormRow>
    
    <UIFormRow class="flex" v-if="['short_answer','long_answer'].includes(field.type)">
        <div class="w-1/2">
            <label class="font-bold text-sm mb-1 block">Karakter Sayısı</label>
            <div class="flex">
                <div class="w-1/2 mr-2">
                    <UIInput
                        label="Minimum"
                        lazy
                        label_class="text-gray-700 font-normal text-xs"
                        v-model="field.min_length"
                    />
                </div>
                <div class="w-1/2">
                    <UIInput
                        label="Maksimum"
                        lazy
                        label_class="text-gray-700 font-normal text-xs"
                        v-model="field.max_length"
                    />
                </div>
            </div>
        </div>
        <div class="w-1/2"></div>
    </UIFormRow>

    <UIFormRow class="flex" v-if="['opinion_scale'].includes(field.type)">
        <div class="w-1/2">
            <label class="font-bold text-sm mb-1 block">Limitler</label>
            <div class="flex">
                <div class="w-1/2 mr-2">
                    <UIInput
                        label="Minimum"
                        lazy
                        label_class="text-gray-700 font-normal text-xs"
                        v-model="field.min_length"
                    />
                </div>
                <div class="w-1/2">
                    <UIInput
                        label="Maksimum"
                        lazy
                        label_class="text-gray-700 font-normal text-xs"
                        v-model="field.max_length"
                    />
                </div>
            </div>
        </div>
        <div class="w-1/2"></div>
    </UIFormRow>
    
    <UIFormRow class="flex" v-if="['slider'].includes(field.type)">
        <div class="w-1/2">
            <label class="font-bold text-sm mb-1 block">Limitler</label>
            <div class="flex">
                <div class="w-1/2 mr-2">
                    <UIInput
                        label="Minimum"
                        lazy
                        label_class="text-gray-700 font-normal text-xs"
                        v-model="field.min_length"
                    />
                </div>
                <div class="w-1/2">
                    <UIInput
                        label="Maksimum"
                        lazy
                        label_class="text-gray-700 font-normal text-xs"
                        v-model="field.max_length"
                    />
                </div>
            </div>
        </div>
        <div class="w-1/2"></div>
    </UIFormRow>
    <UIFormRow class="flex gap-6">
        <UIInput
            type="color"
            label="Başlık Rengi"
            v-model="field.title_colour"
            class="w-1/4"
            size="sm"
            v-if="has_feature(field.type,'title_color')"
        />
        <div class="flex flex-col" v-if="has_feature(field.type,'other_settings')">
            <UILabel label="Diğer Ayarlar" class="mb-2" />
            <UICheckbox
                label="Zorunlu"
                inputValue="is_required"
                :checked="field.is_required == 1"
                class="mb-1"
                @change="onSpecChange"
            />
            <UICheckbox
                label="Gizli"
                inputValue="is_hidden"
                :checked="field.is_hidden == 1"
                class="mb-1"
                @change="onSpecChange"
            />
            <UICheckbox
                label="Salt okunur"
                inputValue="is_readonly"
                :checked="field.is_readonly == 1"
                class="mb-1"
                @change="onSpecChange"
            />
        </div>
    </UIFormRow>
    <UIFormRow v-if="has_feature(field.type,'default_value') && form_data.form_type !== 'mma'">
        <UIRadioGroup
            class="mb-4"
            direction="horizontal"
            key="field_default_value"
            label="Varsayılan Değer"
            title="Varsayılan Değer"
            :options="field_default_value_options"
            v-model="field.default_value_type"
            help_text="Toplantıyı yapmak istediğiniz müşteri, iş ortağı veya sponsor seçin. Toplantı tipini seçtiğinizde ilgili firmalar burada listelenecektir."
        />
        <UIInput
            v-model="field.default_value"
            placeholder="Sabit"
            lazy
            v-if="field.default_value_type == 'FIXED'"
        />
        <UISelect
            display="box"
            lazy
            :source="db_column_fields"
            title="Veritabanı Alanı Seç"
            v-model="field.default_value_db"
            v-if="field.default_value_type == 'DB'"
        />
    </UIFormRow>

</template>

<style>
  @import "@syncfusion/ej2-base/styles/material.css";
  @import "@syncfusion/ej2-inputs/styles/material.css";
  @import "@syncfusion/ej2-lists/styles/material.css";
  @import "@syncfusion/ej2-popups/styles/material.css";
  @import "@syncfusion/ej2-buttons/styles/material.css";
  @import "@syncfusion/ej2-navigations/styles/material.css";
  @import "@syncfusion/ej2-splitbuttons/styles/material.css";
  @import "@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>