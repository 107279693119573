<script setup>
    import { onMounted, ref, watch } from "vue"
    import {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionRoot,
        TransitionChild,
    } from "@headlessui/vue"
	import { useDraggable } from '@vueuse/core'
	import { XIcon } from "@heroicons/vue/solid"
	import LoadAnimVue from "@/views/LoadAnim.vue"
	import FloatingBalloons from "@/views/Backgrounds/FloatingBalloons.vue"
	import Button from '@/components/UI/Button.vue'

	const props = defineProps({
        isOpen : Boolean,
		title : String,
		action_button_text : {
			type : String,
			default : 'OK'
		},
		action_button_hint : String,
		isLoading:{
			type:Boolean,
			default:false
		},
		isSubmitting:{
			type:Boolean,
			default:false
		},
		colored_header:{
			type:Boolean,
			default:false
		},
		size:{
			type:String,
			default:'md'
		},
		close_on_outside_click:{
			type:Boolean,
			default:false
		},
		ready2submit : {
			type:Boolean,
			default:false
		},
		showSubmitButton : {
			type:Boolean,
			default:true
		},
		backdrop : {
			type:Boolean,
			default:true
		},
		is_draggable : {
			type:Boolean,
			default:false
		},
		destructive : {
			type:Boolean,
			default:false
		},
		zero_padding : {
			type:Boolean,
			default:false
		},
		allow_overflow : {
			type:Boolean,
			default:false
		},
		position : String,
		background_animation : String
	})

	const emit = defineEmits(['modal_closed','submit'])

    const isOpened = ref(props.isOpen)
	const drag_wrapper = ref(null)

	onMounted(() => {
		return
		if(props.is_draggable) {
			console.log(drag_wrapper.value)
			const { x, y, style } = useDraggable(drag_wrapper.value, {
				initialValue: { x: 40, y: 40 },
				//handle : drag_handle.value
			})
		}
	})
	

    watch(() => props.isOpen,  () => {
		isOpened.value = props.isOpen
		if(isOpened.value && props.backdrop) {
			document.getElementById('app').classList.add('blur')
		} else {
			document.getElementById('app').classList.remove('blur')
		}
	},
	{ immediate: true })
    
    const closeModal = (outside) => {
		if(outside == 'out' && !props.close_on_outside_click) return
		emit('modal_closed')
		document.getElementById('app').classList.remove('blur')
        isOpened.value = false
    }

	const submit = () => {
		emit('submit')
		//closeModal()
    }



	

</script>


<template>
  	<TransitionRoot appear :show="isOpened" as="template">
    	<Dialog as="div" @close="closeModal('out')">
      		<div class="fixed inset-0 z-20 overflow-y-auto">
        		<div class="min-h-screen px-4 text-center">
          			<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0"
						enter-to="opacity-40"
						leave="duration-200 ease-in"
						leave-from="opacity-40"
						leave-to="opacity-0"
						v-if="backdrop"
					>
					  	<DialogOverlay class="fixed inset-0 opacity-50"
					  		:class="{
								'bg-calypso' : !destructive,
								'bg-red-300' : destructive
							}"
					  	/>
					</TransitionChild>

					<FloatingBalloons v-if="props.background_animation == 'balloons'"/>

					<span class="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>

					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<div 
							:class="{
								'max-w-xs' : size == 'xs',
								'max-w-sm' : size == 'sm',
								'max-w-md' : size == 'md',
								'max-w-lg' : size == 'lg',
								'max-w-xl' : size == 'xl',
								'max-w-2xl' : size == '2xl',
								'max-w-3xl' : size == '3xl',
								'max-w-4xl' : size == '4xl',
								'max-w-5xl' : size == '5xl',
								'max-w-6xl' : size == '6xl',
								'max-w-7xl' : size == '7xl',
								'fixed right-28 bottom-2' : position == 'bottom-right',
								'shadow-xl' : backdrop,
								'shadow-iq' : !backdrop,
								'destructive_bg overflow-hidden' : destructive,
								//'overflow-hidden' : allow_overflow
							}"
							class="inline-block w-full my-8 text-left align-middle transition-all transform bg-white rounded-lg"
							ref="drag_wrapper"
						>
							<DialogTitle as="h3" class="select-none text-lg font-bold leading-6 px-4 py-2 rounded-tl rounded-tr flex items-center" :class="{'hidden' : !title,'cursor-move':is_draggable, 'bg-oz-gradient text-white':colored_header}">
								<span class="truncate">{{title}} </span>
								<a @click="closeModal" class="p-2 opacity-50 hover:opacity-100 rounded-full cursor-pointer transition duration-200 ml-auto" 
									:class="{
										'hover:bg-gray-100/30' : colored_header || destructive,
										'hover:bg-gray-100' : !colored_header,
										'hover:bg-gray-100' : !colored_header
									}">
                					<XIcon class="w-4 h-4"/>
            					</a>
							</DialogTitle>
							<slot name="modal_header_image"></slot>
							<div :class="zero_padding ? '' : 'px-4'">
								<slot name="modal_content"></slot>
							</div>
							<div class="px-4 flex justify-end"
								:class="{
									'mt-4' : !zero_padding,
									'pb-4' : !destructive && !zero_padding,
									'py-4 bg-white' : destructive,
									'py-4' : zero_padding
								}"
							>
								<slot name="modal_footer"></slot>
								<Button :hint="action_button_hint" v-if="showSubmitButton" :disabled="isSubmitting || !ready2submit" :loading="isSubmitting" type="submit" @click="submit" :text="action_button_text"/>
							</div>
							<LoadAnimVue v-if="isLoading" class="bg-white absolute top-0 rounded-lg py-10"/>
						</div>
          			</TransitionChild>
				</div>
			</div>
    	</Dialog>
	</TransitionRoot>
	
</template>

<style scoped>
	.destructive_bg {
		background-image: -webkit-repeating-linear-gradient(135deg,#f04b51,#f04b51 24px,#f2545b 0,#f2545b 48px);
		background-image: repeating-linear-gradient(135deg,#f04b51,#f04b51 24px,#f2545b 0,#f2545b 48px);
		color: #fff;
	}
</style>