//import Popover from '../components/Popover.vue'
import UICard from '../components/UI/Card.vue'
import UIContactCard from '../components/UI/ContactCard.vue'
import UIInput from '../components/UI/Input.vue'
import UICurrencyInput from '../components/UI/CurrencyInput.vue'
import UITable from '../components/UI/Table.vue'
import UITableCell from '../components/UI/TableCell.vue'
import UIBadge from '../components/UI/Badge.vue'
import UITooltip from '../components/UI/Tooltip.vue'
import UIImage from '../components/UI/Image.vue'
import UIList from '../components/UI/List.vue'
import UICheckbox from '../components/UI/Checkbox.vue'
import UICheckboxGroup from '../components/UI/CheckboxGroup.vue'
import UIRadioGroup from '../components/UI/RadioGroup.vue'
import UIRadio from '../components/UI/Radio.vue'
import UIFormRow from '../components/UI/FormRow.vue'
import UITextarea from '../components/UI/Textarea.vue'
import UISelect from '../components/UI/Select.vue'
import UISelectNested from '../components/UI/SelectNested.vue'
import UIScopedUser from '../components/UI/ScopedUserSelect.vue'
import UISelectAutoComplete from '../components/UI/SelectAutoComplete.vue'
import UISwitch from '../components/UI/Switch.vue'
import UITabs from '../components/UI/Tabs.vue'
import UIDatePicker from '../components/UI/DatePicker.vue'
import UIDateTimePicker from '../components/UI/DateTimePicker.vue'
import UIDateRangePicker from '../components/UI/DateRangePicker.vue'
import UIButton from '../components/UI/Button.vue'
import UIFireAlarm from '../components/UI/alert/FireAlarm.vue'
import UIAlert from '../components/UI/alert/Alert.vue'
import UIFloatingAlertList from '../components/UI/alert/FloatingAlertList.vue'
import UIEmptyState from '../components/UI/EmptyState.vue'
import UILabel from '../components/UI/Label.vue'
import UIListbox from '../components/UI/Listbox.vue'
import UIPopover from '../components/UI/Popover.vue'
import UIPopoverMenu from '../components/UI/PopoverMenu.vue'
import UIMenu from '../components/UI/Menu.vue'
import UISplitMenu from '../components/UI/SplitMenu.vue'
import UIWizard from '../components/UI/Wizard.vue'
import UIFileInput from '../components/UI/FileInput.vue'
import UIAvatar from '../components/UI/Avatar.vue'
import UIListgroup from '../components/UI/Listgroup.vue'
import UISideNav from '../components/UI/layouts/SideNav.vue'
import UIFullscreen from '../components/UI/layouts/FullScreen.vue'
import UIDraggable from '../components/UI/Draggable.vue'
import UIPageTitle from '../components/UI/PageTitle.vue'
import UIProgress from '../components/UI/Progress.vue'
import UIConfirm from '../components/UI/Confirm.vue'
import UIButtonSelectInput from '../components/UI/ButtonSelectInput.vue'
import EventCompanyLogo from '../views/EventCompanyLogo.vue'
import UIUpdateableText from '../components/UI/UpdateableText.vue'


export default {
    install: (app) => {
        app.component('UIConfirm',UIConfirm)
        app.component('UIWizard',UIWizard)
        app.component('UIMenu',UIMenu)
        app.component('UISplitMenu',UISplitMenu)
        app.component('UIEmptyState',UIEmptyState)
        app.component('UICard',UICard)
        app.component('UIUpdateableText',UIUpdateableText)
        app.component('UIContactCard',UIContactCard)
        app.component('UIPopover',UIPopover)
        app.component('UIPopoverMenu',UIPopoverMenu)
        app.component('UIInput',UIInput)
        app.component('UIFileInput',UIFileInput)
        app.component('UICurrencyInput',UICurrencyInput)
        app.component('UITable',UITable)
        app.component('UITableCell',UITableCell)
        app.component('UIBadge',UIBadge)
        app.component('UITooltip',UITooltip)
        app.component('UIImage',UIImage)
        app.component('UIList',UIList)
        app.component('UICheckboxGroup',UICheckboxGroup)
        app.component('UICheckbox',UICheckbox)
        app.component('UIRadioGroup',UIRadioGroup)
        app.component('UIRadio',UIRadio)
        app.component('UIFormRow',UIFormRow)
        app.component('UITextarea',UITextarea)
        app.component('UISelect',UISelect)
        app.component('UISelectNested',UISelectNested)
        app.component('UIScopedUser',UIScopedUser)
        app.component('UISelectAutoComplete',UISelectAutoComplete)
        app.component('UISwitch',UISwitch)
        app.component('UITabs',UITabs)
        app.component('UIDatePicker',UIDatePicker)
        app.component('UIDateTimePicker',UIDateTimePicker)
        app.component('UIDateRangePicker',UIDateRangePicker)
        app.component('UIButton',UIButton)
        app.component('UIFireAlarm',UIFireAlarm)
        app.component('UIAlert',UIAlert)
        app.component('UILabel',UILabel)
        app.component('UIFloatingAlertList',UIFloatingAlertList)
        app.component('UIListbox',UIListbox)
        app.component('UIAvatar',UIAvatar)
        app.component('UIListgroup',UIListgroup)
        app.component('UISideNav',UISideNav)
        app.component('UIFullscreen',UIFullscreen)
        app.component('UIDraggable',UIDraggable)
        app.component('UIPageTitle',UIPageTitle)
        app.component('UIProgress',UIProgress)
        app.component('UIButtonSelectInput',UIButtonSelectInput)
        app.component('EventCompanyLogo',EventCompanyLogo)
	}
}