import { createWebHistory, createRouter } from "vue-router"
import Cookies from 'universal-cookie'
import moment from 'moment/min/moment-with-locales'
import store from '../store'
import useGate from './gate'
import API from "../api/index"

import Default from './default.js'
import Admin from './admin.js'
import Projects from './projects.js'
import {format_two_dates} from '@/utils/dates'

const cookies = new Cookies()
const Gate = new useGate()
moment.locale('tr')

const routes = [
	...Default.routes,
	...Admin.routes,
	...Projects.routes
]

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeResolve(async to => {
	
	if (to.meta.requiresProjectData) {
		if(!store.state.project || !store.state.project.current || store.state.project.current.code !== to.params.code) {
			await API.getProject(to.params.code).then(response => {
				store.commit('project/setCurrent',response.data)
				
				store.commit('page_header/setData',{
					title		: response.data.name,
					overtitle 	: response.data.project_ref, 
					subtitle	: response.data.type.name + '<br>' + format_two_dates(response.data.starts_at, response.data.ends_at),
					image		: response.data.client.logo_full
				})
			})
		}
		
		
		return
		
		try {
			await askForCameraPermission()
		} catch (error) {
			if (error instanceof NotAllowedError) {
			// ... handle the error and then cancel the navigation
			return false
			} else {
			// unexpected error, cancel the navigation and pass the error to the global handler
			throw error
			}
		}
	} 
})

router.afterEach((to, from) => {
	//store.commit('content/setPageLoading',false)
	if(!to.meta.custom_header_bg_color) {
		window.mitt.emit('change_header_bg','bg-gradient-to-b from-calypso-light to-white')
	}
})

router.beforeEach((to, from, next) => {
	//store.commit('content/setPageLoading',true)
	
	if(cookies.get('XSRF-TOKEN')) {
		//store.commit('auth/loggedIn',true)
	}

	//Gate.needsLogin(to,next)

	if(!store.state.auth.isAuthenticated && localStorage.getItem('manuelRSVPUser') && to.name !== 'ManuelRSVPLogin'){
		const user = JSON.parse(localStorage.getItem('manuelRSVPUser'))
		
		API.setHeaders(user.token)
	
		store.commit('auth/loggedIn',true)
		store.commit('auth/setMe',user.user)
		store.commit('auth/setAccessToken',user.token)
		API.getMe()

		store.commit('content/setAppReady',true)

		next()
		return
	}

	if(!store.state.auth.isAuthenticated && to.meta.requiresAuth && !localStorage.getItem('user') ) {
		store.commit('auth/setRedirectAfterLogin',to)
		store.commit('content/setAppReady',true)
        
		  next( {
		  	path: '/login'
		  })
		return
	} 
		
	// Redirect to home page if user is 
	if(localStorage.getItem('user') && to.name == 'Login'){
		next( {
			path: '/'
		})
		return

	}


	if(!store.state.auth.isAuthenticated && localStorage.getItem('user') && to.name !== 'Login'){
		const user = JSON.parse(localStorage.getItem('user'))
		
		API.setHeaders(user.token)
	
		store.commit('auth/loggedIn',true)
		store.commit('auth/setMe',user.user)
		store.commit('auth/setAccessToken',user.token)
		API.getMe()
		
		/*if(!user.onboarded_at) {
			store.commit('content/setAppReady',true)
			next( {
				path: '/onboarding'
			})
			return
		}*/

		API.load_primary_data()
	}

	switch (to.name) {
		case 'ProjectDetail':
			API.getProject(to.params.code).then(response => {
				store.commit('project/setCurrent',response.data)
				store.commit('page_header/setData',{
					title		: response.data.name,
					overtitle 	: response.data.project_ref, 
					subtitle	: response.data.project_type.name + '<br>' + format_two_dates(response.data.starts_at, response.data.ends_at),
					image		: response.data.client.logo_full
				})
				next()
			})
			break
		case 'ClientDetail':
			API.get('client/' + to.params.code).then(response => {
				if(!response.data.name) {
					next( {
						name: 'Clients'
					})
					return
				}
				store.commit('page_header/setData',{
					title		: response.data.name,
					//overtitle 	: response.data.project_ref, 
					subtitle	: response.data.industry.name + '<br>' + (response.data.city || 'Şehir bilgisi girilmemiş') + ', ' + response.data.country.name,
					image		: response.data.logo_full
				})
				
				store.commit('clients/setCurrentClient',response.data)
				
				next()
			})
			break
		case 'SupplierDetail':
			API.get('suppliers/' + to.params.code).then(response => {
				store.commit('page_header/setData',{
					title		: response.data.name,
					//overtitle 	: ,
					subtitle	: response.data.categories.map(x=>x.name).join(', ') + '<br>' + response.data.city + ', ' + response.data.country.name
				})
				
				store.commit('suppliers/setCurrentSupplier',response.data)
				
				next()
			})
			break
		case 'BizdevDetail':
			
			API.get('leads/' + to.params.code).then(response => {
				store.commit('project/setCurrent',response.data)
				
				if(response.data.name) {
					store.commit('page_header/setData',{
						title		: response.data.name,
						overtitle 	: response.data.project_ref, 
						subtitle	: response.data.project_type.name + '<br>' + format_two_dates(response.data.starts_at, response.data.ends_at),
						image		: response.data.client.logo_full
					})
				}
				next()
			})
			
			break

		case 'SponsorDetail':
			
			API.get('project/'+to.params.code+'/sponsors/' + to.params.scode).then(response => {
				
				
				if(response.data.sponsor_name) {
					store.commit('page_header/setData',{
						title		: response.data.sponsor_name,
						overtitle 	:'', 
						subtitle	: response.data.type.name + '<br>' + moment(response.data.created_at).format('DD MMMM YYYY'),
						image		: ''
					})
				}
				next()
			})
			
			break

		case 'AdminPostEventClientSurveyDetail':
			API.get('form/' + to.params.id).then(response => {
				store.commit('form/setForm',response.data)
				
				if(response.data.name) {
					store.commit('page_header/setData',{
						title		: response.data.name,
						subtitle	: response.data.form_type
					})
				}
				next()
			})
			break

		case 'AdminBizDevSubpage':

			store.commit('page_header/setData',{
				title		: 'TEKLİF YÖNETİMİ',
				subtitle	: 'Yeni proje eklerken kullanılmak üzere'
			})

			if(to.params.subpage !== 'brief-form') {
				next()
			} else {
				API.get('event-companies/brief-form').then(response => {
					store.commit('form/setForm',response.data)
					
					next()
				})
			}
			break
		
		case 'Login':
			store.commit('content/setAppReady',true)	
			next()
			break

		case 'ApprovalDecision':
			store.commit('content/setAppReady',true)	
			next()
			break				

		case 'ForgotPassword':
			store.commit('content/setAppReady',true)	
			next()
			break

		case 'PasswordReset':
			store.commit('content/setAppReady',true)	
			next()
			break
		
		case 'WebForm':
			store.commit('content/setAppReady',true)	
			API.get('webform/' + to.params.slug + (to.params.pcode ? '/' + to.params.pcode : ''))
			.then(response => {
				store.commit('webform/setForm',response.data)		
				next()
			})
			.catch(error => {
				store.commit('webform/setError',error.response.data.message)		
				next()
			})
			break

		case 'PostEventSurveyForm':
			store.commit('content/setAppReady',true)	
			API.get('post-event-survey/' + to.params.code).then(response => {
				store.commit('webform/setPESFForm',response.data)
				next()
			})
			break
		case 'CustomerForm':
			store.commit('content/setAppReady',true)	
			next()
			break
		case 'ManuelRSVPLogin':
			store.commit('content/setAppReady',true)	
			next()
			break
		case 'ClientReview':
			store.commit('content/setAppReady',true)	
			next()
			break
		case 'SponsorReview':
			store.commit('content/setAppReady',true)
			next()
			break
		case 'JudgeReview':
			store.commit('content/setAppReady',true)
			next()
			break
		case 'Checkin':
			store.commit('content/setAppReady',true)	
			next()
			break
		case 'Decleration':
			store.commit('content/setAppReady',true)
			next()
			break	
		default:
			
			store.commit('page_header/setData',{
				title		: to.meta.title,
				overtitle 	: to.meta.overtitle,
				subtitle	: to.meta.subtitle,
				image		: to.meta.image,
			})
			next()
	}
	
})

router.afterEach((to, from, failure) => {
	//console.log("navigation completed")
})

const logRoute = (from,to) => {
	
}

export default router;