<template>
  	<div ref="target"
	  	:class="{
			'w-full h-full' : fit_parent
		}"
	>
		<UILabel :help_text="help_text" :label="label" :rand_id="id || rand_id" :isFocused="isFocused" @click="show_cal"/>
		
		<Popover class="relative"
			:class="{
                'w-full h-full' : fit_parent
            }"
		>
			<PopoverButton ref="date_picker_button" v-slot="{ isOpen }" @click="show_cal" 
				class="flex items-center"
				:class="{
					'w-full rounded-iq focus:outline-none focus:ring-calypso border border-gray-300 bg-gray-50 p-2 h-[42px]' : display == 'box',
					'font-boldd' : display == 'inline' && selected_date_text !== title,
					'text-gray-500' : selected_date_text == title,
					'text-sm' : size == 'small',
					'text-normal' : size == 'normal',
					'text-calypso' : color == 'calypso',
					'w-full h-full pl-2 outline-0' : fit_parent
				},[props.title_color]"
			>
				<CalendarIcon v-if="icon" class="w-4 h-4 text-calypso mr-1" /> {{selected_date_text}} <ChevronDownIcon class="w-4 h-4 text-calypso duration-300" :class="{'ml-auto':display == 'box', 'transform rotate-180':isOpen}" v-if="use_arrow" /> 
			</PopoverButton>

			<transition
			v-if="isOpen"
				enter-active-class="transition duration-200 ease-out"
				enter-from-class="translate-y-1 opacity-0"
				enter-to-class="translate-y-0 opacity-100"
				leave-active-class="transition duration-150 ease-in"
				leave-from-class="translate-y-0 opacity-100"
				leave-to-class="translate-y-1 opacity-0"
			>
				<div>
					<PopoverPanel 
						class="absolute z-10 mt-2 shadow bg-white border border-gray-iq shadow-iq rounded-iq w-56"
						:class="{
							'-translate-y-full -top-5' : popover_position == 'up',
						}"
						static
					>
						<div class="-top-2 left-5 w-4 h-4 absolute bg-white border-r border-b border-gray-iq -rotate-135 transform -z-1" v-if="popover_position == 'down'"></div>
						<div :class="{
							'mt-3 pb-1' : popover_position == 'down',
							'mb-3 pt-1' : popover_position == 'up',
						}">
							<template v-if="shortcuts">
							<div class="px-3 py-1 hover:bg-calypso-light flex items-center cursor-pointer" @click="set_date(moment())">
								<svg width="24" height="24" viewBox="0 0 24 24" class="text-lime-600 mr-2" aria-hidden="true" focusable="false"><g fill="currentColor" fill-rule="evenodd"><path fill-rule="nonzero" d="M6 4.5h12A1.5 1.5 0 0 1 19.5 6v2.5h-15V6A1.5 1.5 0 0 1 6 4.5z" opacity=".1"></path><path fill-rule="nonzero" d="M6 4h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H6zm1 3h10a.5.5 0 1 1 0 1H7a.5.5 0 0 1 0-1z"></path><text font-size="9" transform="translate(4 2)" font-weight="500"><tspan x="8" y="15" text-anchor="middle">{{ moment().format('DD')}}</tspan></text></g></svg>
								<span class="text-sm">Bugün</span>
								<span class="ml-auto text-gray-400 text-xs">{{ moment().format('dddd')}}</span>
							</div>
							<div class="px-3 py-1 hover:bg-calypso-light flex items-center cursor-pointer" @click="set_date(moment().add(1, 'days'))">
								<svg width="24" height="24" viewBox="0 0 24 24" class="text-yellow-600 mr-2" aria-hidden="true" focusable="false"><g fill="currentColor" fill-rule="nonzero"><path d="M12 15.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" opacity=".1"></path><path d="M9.704 17.543a.5.5 0 0 1 .27.654l-.956 2.31a.5.5 0 0 1-.924-.383l.957-2.31a.5.5 0 0 1 .653-.27zm5.245.27l.957 2.31a.5.5 0 0 1-.924.383l-.956-2.31a.5.5 0 0 1 .923-.382zM12 7.5a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9zm-5.543 6.796a.5.5 0 0 1-.27.653l-2.31.957a.5.5 0 0 1-.383-.924l2.31-.956a.5.5 0 0 1 .653.27zm11.74-.27l2.31.956a.5.5 0 0 1-.383.924l-2.31-.957a.5.5 0 0 1 .383-.923zM12 8.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zm-8.124-.406l2.31.957a.5.5 0 0 1-.383.923l-2.31-.956a.5.5 0 0 1 .383-.924zm16.9.27a.5.5 0 0 1-.27.654l-2.31.956a.5.5 0 0 1-.382-.923l2.31-.957a.5.5 0 0 1 .653.27zM9.019 3.495l.956 2.31a.5.5 0 0 1-.923.382l-.957-2.31a.5.5 0 1 1 .924-.382zm6.617-.27a.5.5 0 0 1 .271.652l-.957 2.31a.5.5 0 0 1-.923-.383l.956-2.31a.5.5 0 0 1 .653-.27z"></path></g></svg>
								<span class="text-sm">Yarın</span>
								<span class="ml-auto text-gray-400 text-xs">{{ moment().add(1, 'days').format('dddd')}}</span>
							</div>
							<div class="px-3 py-1 hover:bg-calypso-light flex items-center cursor-pointer" @click="set_date(moment().add(7, 'days'))">
								<svg width="24" height="24" viewBox="0 0 24 24" class="text-purple-600 mr-2" aria-hidden="true" focusable="false"><g fill="currentColor" fill-rule="evenodd"><path fill-rule="nonzero" d="M6 4.5h12A1.5 1.5 0 0 1 19.5 6v2.5h-15V6A1.5 1.5 0 0 1 6 4.5z" opacity=".1"></path><path fill-rule="nonzero" d="M18 4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h12zm0 1H6a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zm-2.109 8.188l.007.01-.004-.005-.003-.005zM17 8a.5.5 0 1 1 0 1H7a.5.5 0 0 1 0-1h10zm-1 5.52a.504.504 0 0 1-.023.131l-.015.04a.494.494 0 0 1-.05.093l-.014.018a.503.503 0 0 1-.033.04l-2.511 2.512a.5.5 0 0 1-.765-.638l.057-.07L14.292 14H8.5a.5.5 0 0 1-.492-.41L8 13.5a.5.5 0 0 1 .41-.492L8.5 13h5.792l-1.646-1.646a.5.5 0 0 1 .638-.765l.07.057 2.511 2.513.017.019.009.01-.027-.03.03.035.029.04a.52.52 0 0 1 .066.162l.008.052v.007l-.002-.026.005.072v.02z"></path></g></svg>
								<span class="text-sm">Haftaya</span>
								<span class="ml-auto text-gray-400 text-xs">{{ moment().add(7, 'days').format('DD MMMM dddd')}}</span>
							</div>
							<!--<div class="px-3 py-1 hover:bg-calypso-light flex items-center cursor-pointer" @click="set_date('')">
								<svg width="24" height="24" viewBox="0 0 24 24" class="text-gray-600 mr-2" aria-hidden="true" focusable="false"><path fill="currentColor" fill-rule="nonzero" d="M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 1a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm3.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"></path></svg>
								<span class="text-sm">Tarih Yok</span>
							</div>-->
							</template>
							<input ref="date_picker" :id="rand_id" type="hidden">
						</div>
						<div class="-bottom-2 left-5 w-4 h-4 absolute bg-white border-l border-t border-gray-iq -rotate-135 transform -z-1" v-if="popover_position == 'up'"></div>
					</PopoverPanel>
				</div>
			</transition>
		</Popover>
	</div>
</template>

<script setup>
	import { ref,computed,inject,watch, onUnmounted } from 'vue';
	import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
	import { onClickOutside } from '@vueuse/core'
	import flatpickr from "flatpickr"
	import { Turkish } from "flatpickr/dist/l10n/tr.js"
	import 'flatpickr/dist/themes/light.css';
	import { ChevronDownIcon } from '@heroicons/vue/solid'
	import { CalendarIcon, ClockIcon } from '@heroicons/vue/outline'
	const moment = inject('moment')

	const props = defineProps({
        modelValue:[String,Number,Array,Object],
		title: String,
		title_color : {
            type : String,
            default : 'text-calypso'
        },
		label: String,
		id : String,
		minDate : String,
		maxDate : String,
		defaultDate : String,
		help_text : String,
		display : {
            type : String,
            default : 'inline'
        },
		shortcuts : {
            type : Boolean,
            default : true
        },
		use_arrow:{
            type:Boolean,
            default:true
        },
		time : {
            type : Boolean,
            default : false
        },
		mode : {
            type : String,
            default : 'single'
        },
		size : {
            type : String,
            default : 'small'
        },
		color : {
            type : String,
            default : 'calypso'
        },
		icon : {
            type : Boolean,
            default : true
        },
		format : {
			type:String,
			default:'DD MMMM YYYY'
		},
		fit_parent:{
            type:Boolean,
            default:false
        }
    })

	const rand_id = computed(()=>'date_input_' + (Math.floor(Math.random() * 1000000000)))
	
	const emit = defineEmits(['update:modelValue','focus'])
	
	const target = ref(null)
	const date_picker = ref(null)
	const date_picker_button = ref(null)
	const minDate = ref('')
	const maxDate = ref('')
	const selected_date_text = props.modelValue ? ref(props.modelValue) : ref(props.title)
	const selected_date = ref(props.title)
	const isOpen = ref(false)
	const isFocused = ref(false)
	
	let picker

	const setPickerOption = (key,value) => {
		if(!picker) return
		picker.set(key,value)
	}

	const close_modal = () => {
		isOpen.value = false
		picker = null
	}

	onClickOutside(target, close_modal)

	const set_date = (date, needs_emit=true) => {
		if(!date || date == "") {
			selected_date_text.value = props.title
			selected_date.value = null
		} else {
			selected_date_text.value = props.time ? date.format('DD MMMM YYYY HH:mm') : date.format(props.format)
			selected_date.value = props.time ? date.format('YYYY-MM-DD HH:mm:ss') : date.format('YYYY-MM-DD')
		}

		if(!props.time) {
			close_modal()
		}
		
		if(needs_emit) emit('update:modelValue',selected_date.value)
	}

	//if(props.modelValue) set_date(moment(props.modelValue))
	
	watch(() =>  props.modelValue, data => {
		set_date(props.modelValue ? moment(props.modelValue) : null,false)
    }, {immediate:true})

	const popover_position = ref('down')

	const calculate_position = () => {
		const distance_to_bottom = window.innerHeight - date_picker_button.value.el.getBoundingClientRect().y
		popover_position.value = distance_to_bottom < 400 ? 'up' : 'down'
	}

	const set_picker = () => {
		if(picker) return
		
		picker = flatpickr(date_picker.value, {
					inline:true,
					monthSelectorType:'static',
					locale:Turkish,
					mode : props.mode,
					defaultDate: props.modelValue,
					minDate: minDate.value,
					maxDate: maxDate.value,
					enableTime: props.time,
					time_24hr: true,
					static : true,
					onChange: (selectedDates, dateStr, instance) => {
						set_date(moment(selectedDates[0]))
						if(!props.time && props.mode != 'range') {
							close_modal()
						}
					}
				})
	}

	const show_cal = () => {
		emit('focus',!isOpen.value)
		if(isOpen.value) {
			close_modal()
			return
		}
		isOpen.value = true
		calculate_position()
		setTimeout(set_picker,10)
	}

	watch(() =>  props.minDate, data => {
		//minDate.value = data || !props.modelValue ? 'today' : null
		minDate.value = data
		setPickerOption('minDate', minDate.value)
    }, {immediate:true})

	watch(() =>  props.maxDate, data => {
		maxDate.value = data
		setPickerOption('maxDate', maxDate.value);
    }, {immediate:true})
	
</script>

<style scoped>
	.flatpickr-input {
		display: none;
	}
	:global(.flatpickr-months .flatpickr-month) {
		background-color: #fff;
	}

	:global(.flatpickr-weekdays) {
		background-color: #fff;
	}

	:global(span.flatpickr-weekday) {
		background-color: #fff;
	}

	:global(.flatpickr-days) {
		border: none;
		width: 200px;
	}

	:global(.flatpickr-day) {
		height: 28.50px;
		line-height: 26.50px;
	}

	:global(.flatpickr-calendar.inline) {
		box-shadow: none;
		width: 222px !important;
	}

	:global(.flatpickr-innerContainer) {
    	border: none;
		width: 200px;
		margin: 0 10px;
	}
	:global(.flatpickr-current-month span.cur-month:hover) {
    	background-color: transparent;
	}

	:global(.flatpickr-current-month .numInputWrapper) {
		width:7ch;
	}

	:global(.dayContainer) {
		width: 200px;
		min-width: 200px;
		max-width: 200px;
	}

	:global(.flatpickr-current-month){
    	font-size: 1rem;
	}

	:global(.numInputWrapper:hover) {
    	background: transparent;
	}

	:global(.flatpickr-calendar.hasTime .flatpickr-time) {
		border: none !important;
		border-top: 1px solid #cbd6e2 !important;
		margin-top: 5px;
    	padding-top: 5px;
	}

	:global(.flatpickr-time input:hover,.flatpickr-time input:focus) {
		background: transparent !important;
	}

</style>