import axios from 'axios'
import { set_interceptors } from './interceptors'
import store from '../store/index'
import Bugsnag from '@bugsnag/js'

let root_url, startup_loads_max, startup_loads_loaded = 0, get_accounting_data_cancel, get_projects_cancel, get_clients_cancel, get_suppliers_cancel, get_attendees_cancel, get_saveFormField_cancel

const CancelToken = axios.CancelToken

axios.defaults.withCredentials = true

axios.defaults.params = {
    'locale':'tr'
}

// ChatgPT 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

axios.defaults.headers = {
    'Accept':'application/json'
}

set_interceptors(axios)

if(window.location.hostname == 'localhost') {
    root_url = 'http://localhost'
    //root_url = 'http://127.0.0.1:8000'
    axios.defaults.baseURL = root_url + '/api/v1'
} else if(window.location.hostname == '192.168.1.21') {
    root_url = 'https://192.168.1.21'
    //root_url = 'http://127.0.0.1:8000'
    axios.defaults.baseURL = root_url + '/api/v1'
} else {
    root_url = 'https://xhqfa3htizxfzgkrv5fsopeiiu0uaazf.lambda-url.eu-west-2.on.aws'
    axios.defaults.baseURL = root_url + '/api/v1'
}


export default {

    async get (url,params=null,config=null) {
        return await axios.get(url, {params:params}, config)
    },

    async post (url,params=null,config=null) {
        return await axios.post(url, params, config)
    },

    async put (url,params=null,config=null) {
        return await axios.put(url, params, config)
    },
    
    async delete (url,params=null,config=null) {
        return await axios.delete(url, {params:params},config)
    },

    async getCSRF () {
        return axios.get(root_url + '/sanctum/csrf-cookie')
    },
    async load_primary_data() {
        this.getProjects({paginate:true,page:1, page_size:20,sort_by:'starts_at',sort_order:'asc',statuses:'PREP,OPERATION,PRECLOSE'}).then(response => {
            store.commit('projects/setProjects',response.data.data)
            delete response.data.data
            store.commit('projects/setPaginator',response.data)
        })
        .catch(function(err){
            if(axios.isCancel(err)){
                //console.log('im canceled')
            } else{
                //console.log('im server response error')
            }
        })
        
        //this.getSuppliers({page:1, page_size:20,sort_by:'name',sort_order:'asc'})
	    //this.getTasks()
        const startup_loads = [
            this.getClients({page:1, page_size:20,sort_by:'name',sort_order:'asc', paginate:false}),
            this.getUsers(),
            this.getTeams(),
            this.getDivisions(),
            this.getProjectTypes(),
            this.getLanguages(),
            this.getCountries(),
            this.getContacts(),
            this.getTaskLabels(),
            this.getMeetingTypes(),
            this.getFormFieldTemplates(),
            this.getBudgetCategories(),
            this.getClientIndustries(),
            this.getSupplierCategories(),
            this.getCurrencies(),
            this.getContactPositions(),
            this.getUserPositions(),
            this.getTodaysExchangeRates(),
            this.getEventCompany(),
            this.getExpenseSources()
        ]

        startup_loads_max = startup_loads.length
        
        startup_loads.forEach(promise => {
            promise.then(() => window.mitt.emit('startup_load_status', startup_loads_loaded++ / (startup_loads_max-1))).catch(error => console.log(error))
        })
        /*
        this.getUsers()
        this.getTeams()
        this.getDivisions()
        this.getEventCompany()
        this.getProjectTypes()
        this.getLanguages()
        this.getCountries()
        this.getContacts()
        this.getTaskLabels()
        this.getFormFieldTemplates()
        this.getBudgetCategories()
        this.getClientIndustries()
        this.getSupplierCategories()
        this.getCurrencies()
        this.getContactPositions()
        this.getTodaysExchangeRates()
        */
        
        Promise.all(startup_loads).then(values => {
            window.mitt.emit('splash_animate_out')
            setTimeout(()=>{
                store.commit('content/setAppReady',true)
            },250)
            this.load_secondary_data()
        })
        
    },
    load_secondary_data() {
        this.getLeads()
        this.getDefBudgetItems()
        this.getDefBudgetGroups()
    },
    async login (email, password) {
        let response = await axios.post('/login',{ email : email, password : password })
        
        localStorage.setItem('user', JSON.stringify(response.data))
        this.setHeaders(response.data.token)
        
        store.commit('auth/loggedIn',true)
        store.commit('auth/setMe',response.data.user)
        store.commit('auth/setAccessToken',response.data.token)
        
        await this.load_primary_data()
    }, 
    async manuelRSVPLogin (token) {
        let response = await axios.post('/manuel-rsvp/login',{ token : token })
        
        localStorage.setItem('manuelRSVPUser', JSON.stringify(response.data))
        this.setHeaders(response.data.token)
        
        store.commit('auth/loggedIn',true)
        store.commit('auth/setMe',response.data.user)
        store.commit('auth/setAccessToken',response.data.token)
        store.commit('content/setAppReady',true)
    },

    setHeaders (access_token) {
        axios.defaults.headers["Authorization"] = 'Bearer ' + access_token
    },

    async getMe () {
        let me = await axios.get('/me')
        store.commit('auth/setMe',me.data)
        Bugsnag.setUser(me.data.id, me.data.email, me.data.full_name)
        //localStorage.setItem('user', JSON.stringify(me.data))
    },
    async getLeads () {
        let leads = await axios.get('/leads')
        store.commit('projects/setLeads',leads.data)
    },
    async getEventCompany () {
        let event_company = await axios.get('/event-companies/' + store.state.auth.me.event_company_id )
        store.commit('auth/setEventCompany',event_company.data)
    },
    async getTodaysExchangeRates() {
        let languages = await axios.get('/exchange-rate') 
        store.commit({type:'content/setData',field:'exchange_rates',response:languages.data})
    },
    async getLanguages () {
        let languages = await axios.get('/languages') 
        store.commit({type:'content/setData',field:'languages',response:languages.data})
    },
    async getContactPositions () {
        let positions = await axios.get('/definition/contact-positions') 
        store.commit({type:'content/setData',field:'contact_positions',response:positions.data})
    },
    async getExpenseSources () {
        let sources = await axios.get('/definition/expense-sources') 
        store.commit({type:'content/setData',field:'expense_sources',response:sources.data})
    },
    async getDefBudgetItems () {
        let items = await axios.get('/definition/budget-items') 
        store.dispatch('budget/updateDefItems',items.data)
    },
    async getDefBudgetGroups () {
        let groups = await axios.get('/definition/budget-groups')
        store.dispatch('budget/updateDefGroups',groups.data)
    },
    async getUserPositions () {
        let positions = await axios.get('/definition/positions') 
        store.commit({type:'content/setData',field:'user_positions',response:positions.data})
    },

    async getCountries () {
        let countries = await axios.get('/definition/countries') 
        store.commit({type:'content/setData',field:'countries',response:countries.data})
    },

    async getProjectTypes () {
        let project_types = await axios.get('/definition/project-types') 
        store.commit({type:'content/setData',field:'project_types',response:project_types.data})
    },

    async getBudgetCategories () {
        let categories = await axios.get('/definition/budget-categories') 
        store.commit({type:'content/setData',field:'budget_categories',response:categories.data})
    },

    async getClientIndustries () {
        let industries = await axios.get('/definition/client-industries')
        store.commit({type:'content/setData',field:'client_industries',response:industries.data})
    },

    async getSupplierCategories () {
        let categories = await axios.get('/definition/supplier-categories')
        store.commit({type:'content/setData',field:'supplier_categories',response:categories.data})
    },

    async getCurrencies () {
        let currencies = await axios.get('/currencies') 
        store.commit({type:'content/setData',field:'currencies',response:currencies.data})
    },

    async getFormFieldTemplates (){
        let templates = await axios.get('/forms/field-templates')
        store.commit({type:'content/setData',field:'form_field_templates',response:templates.data})
    },

    async getMeetingTypes (){
        let templates = await axios.get('/definition/meeting-types')
        store.commit({type:'content/setData',field:'meeting_types',response:templates.data})
    },

    getBudget (id){
        return axios.get('/budget/'+id)
        //store.commit('content/setFormFieldTemplates',templates.data)
    },

    saveFormField (id, data) {
        if(get_saveFormField_cancel) get_saveFormField_cancel()

        return axios({
            method: 'put',
            url: '/form/field/'+id,
            params: data,
            cancelToken: new CancelToken(function executor(c) {
                get_saveFormField_cancel = c
            })
        })
    },

    getProjects (filters) {
        //if(get_projects_cancel) get_projects_cancel()
        
        return axios({
            method: 'get',
            url: '/projects',
            params: filters,
            cancelToken: new CancelToken(function executor(c) {
                get_projects_cancel = c
            })
        })
    },

    deleteProject (code) {
        return axios({
            method: 'delete',
            url: '/leads/' + code
        })
    },

    getProjectsByStatus (statuses) {
        return axios({
            method: 'get',
            url: '/projects',
            params: {
                statuses    : statuses.join(','),
                sort_by     : 'name',
                sort_order  : 'asc'
            }
        })
    },

    async getBudgets (project_code) {
        store.commit('project/setBudgets',[])
        let budgets = await axios.get('/project/'+project_code+'/budgets')
        store.commit('project/setBudgets',budgets.data)
    },

    async getTimeline (project_code) {
        let timeline = await axios.get('/project/'+project_code+'/timeline')
        store.commit('project/setTimeline',timeline.data)
    },

    async getFiles (project_code) {
        let files = await axios.get('/project/'+project_code+'/files') 
        store.commit('project/setFiles',files.data)
    },

    async getPricingPeriods (project_code) {
        let pricing_periods = await axios.get('/project/'+project_code+'/pricing-periods') 
        store.commit('project/setPricingPeriods',pricing_periods.data)
    },

    async getProducts (project_code) {
        let products = await axios.get('/project/'+project_code+'/products') 
        store.commit('project/setProducts',products.data)
    },

    getChecklistTemplates (type) {
        return axios.get('/checklists/', {
            params:{
                project_type:type
            }
        }) 
    },

    getChecklist (checklist_id) {
        return axios.get('/checklists/'+checklist_id) 
    },

    async getSponsors (project_code) {
        let sponsors = await axios.get('/project/'+project_code+'/sponsors') 
        //store.commit('project/setSponsors',sponsors.data)
        store.commit({type:'project/setData',response:sponsors.data, field:'sponsors'})
    },
    
    async getFlights (project_code) {
        let flights = await axios.get('/project/'+project_code+'/flights') 
        store.commit('project/setFlights',flights.data)
    },

    async searchFlights (airline_code,flight_code,date) {
        return axios.get('/flight/search',{
            params:{
                airline_code:airline_code,
                flight_code:flight_code,
                date:date
            }
        }) 
    },

    getAccountingData (type, project_code,filters) {
        if(get_accounting_data_cancel) get_accounting_data_cancel()
        const post_filters = {...filters, ...{dates:filters.dates.join()}}
        
        axios({
            method: 'get',
            url: project_code ? '/project/'+project_code+'/'+type : '/accounting/'+type,
            params: post_filters,
            cancelToken: new CancelToken(function executor(c) {
                get_accounting_data_cancel = c
            })
        })
        .then(response => {
            if(project_code) {
                store.commit({type:'project/setData',response:response.data.invoices.data,field:type})
                delete response.data.invoices.data
                
                store.commit({type:'project/setData',response:response.data.invoices,field:type+'_paginator'})    
                store.commit({type:'project/setData',response:response.data.total,field:type+'_total'})    
                
            } else {
                store.commit({type:'accounting/setData',response:response.data.invoices.data,field:type})
                delete response.data.invoices.data
                store.commit({type:'accounting/setData',response:response.data.invoices,field:type+'_paginator'})
            }
        })
        .catch(function(err){
            if(axios.isCancel(err)){
                //console.log('im canceled')
            } else{
                //console.log('im server response error')
            }
        })
    },
    

    getClients (filters) {
        return new Promise((resolve, reject) => {
            if(get_clients_cancel) get_clients_cancel()
        
            axios({
                method: 'get',
                url: '/clients',
                params: filters,
                cancelToken: new CancelToken(function executor(c) {
                    get_clients_cancel = c
                })
            })
            .then(response => {
                if(filters.paginate) {
                    store.commit('clients/setClients',response.data.data)
                    delete response.data.data
                    store.commit('clients/setPaginator',response.data)
                } else {
                    store.commit('clients/setClients',response.data)
                }
                resolve()
            })
            .catch(function(err){
                console.log(err)
                if(axios.isCancel(err)){
                    //console.log('im canceled')
                } else{
                    //console.log('im server response error')
                }
                reject()
            })  
        })
    },

    getSuppliers (filters) {
        if(get_suppliers_cancel) get_suppliers_cancel()
        
        axios({
            method: 'get',
            url: '/suppliers',
            params: filters,
            cancelToken: new CancelToken(function executor(c) {
                get_suppliers_cancel = c
            })
        })
        .then(response => {
            if(filters.paginate) {
                store.commit('suppliers/setSuppliers',response.data.data)
                delete response.data.data
                store.commit('suppliers/setPaginator',response.data)
            } else {
                store.commit('suppliers/setSuppliers',response.data)
            }
        })
        .catch(function(err){
            if(axios.isCancel(err)){
                //console.log('im canceled')
            } else{
                //console.log('im server response error')
            }
        })
    },
    getAttendees (filters) {
        if(get_attendees_cancel) get_attendees_cancel()
        
        axios({
            method: 'get',
            url: '/attendees',
            params: filters,
            cancelToken: new CancelToken(function executor(c) {
                get_attendees_cancel = c;
            })
        })
        .then(response => {
            if(!filters.page_size){
                console.log(response.data);
                store.commit('attendees/setAttendees',response.data)
            }else{
                store.commit('attendees/setAttendees',response.data.data)
                delete response.data.data
                store.commit('attendees/setPaginator',response.data)
            }
        })
        .catch(function(err){
            if(axios.isCancel(err)){
                //console.log('im canceled')
            } else{
                //console.log('im server response error')
            }
        })
    },

    async getAttendee (attendee_id,project_id) {
        return axios.get('/attendee',{params:{attendee_id:attendee_id,project_id:project_id}}) 
    },

    async search (query) {
        return axios.get('/search',{params:{query:query}}) 
    },
    
    getForms (project_id) {
        return axios.get('/forms',{params:{project_id:project_id}}) 
    },

    getFormData (form_id) {
        return axios.get('/form/'+form_id) 
    },
    deleteForm(form_id) {
        return axios.delete('/form/'+form_id) 
    },
    cloneForm(form_id) {
        return axios.post('/form/'+form_id+'/clone') 
    },
    getFormFieldData (form_id) {
        return axios.get('/form/field/'+form_id) 
    },
    

    async getContacts () {
        let contacts = await axios.get('/contacts') 
        store.commit({type:'content/setData',field:'contacts',response:contacts.data})
    },

    async getClientSupplierContacts (type, company_id) {
        return await axios.get('/contacts',{
            params: {type:type,for:company_id}
        })
    },

    /*
    API.get('contacts',{type:'C',for:data}).then(response => {
        contacts.value = response.data.map(x=>{
            return {text:x.name, subtext:x.position.name,value:x.id, image : x.profile_picture_full}
        })
    })
    */

    getProject (project_id) {
        return axios.get('/project/' + project_id)
    },

    

    getUserInspection (inspection_id) {
        return axios.get('/user/inspection/' + inspection_id) 
    },

    async getUsers (filters) {
        let users = await axios.get('/users') 
        //useStorage('users', users.data)
        store.commit({type:'content/setData',field:'users',response:users.data})
    },

    async getTeams (filters) {
        let teams = await axios.get('/teams') 
        //useStorage('teams', teams.data)
        store.commit({type:'content/setData',field:'teams',response:teams.data})
    },

    async getDivisions (filters) {
        let divisions = await axios.get('/divisions') 
        //useStorage('divisions', divisions.data)
        store.commit({type:'content/setData',field:'divisions',response:divisions.data})
    },

    async getTasks (filters, append = false) {
        return new Promise(async (resolve, reject) =>  {
            let tasks = await axios.get('/tasks',{
                params: filters
            })
            store.commit('tasks/setTasks',{
                //list:filters.due == 'completed' ? 'completed' : 'pending',
                list:'pending',
                tasks:tasks.data.tasks.data,
                sections:tasks.data.sections,
                append: append
            })
            resolve()
        })
    },

    addClient (data) {
        return axios.post('/client',data)
    },
    async getTaskLabels () {
        let labels = await axios.get('/tasks/label') 
        store.commit('tasks/setLabels',labels.data)
    },

    uploadProjectFile(project_code,files,onUploadProgress) {
        let formData = new FormData()
        console.log(files)
        Array.from(files).forEach(file=>{
            formData.append("files[]", file)    
        })
        axios.post('/project/'+project_code+'/file', formData, {onUploadProgress: onUploadProgress}).then(res => {
            console.log(res)
            this.getFiles (project_code)
            if (res.data.status == 200) {
              console.log("done: ", res.data.message);
            }
          }).catch(err => {
            console.log("error: ", err.message);
          })
    },
    uploadFiles(url,files,onUploadProgress) {
        let formData = new FormData()
        Array.from(files).forEach(file=>{
            formData.append("files[]", file)    
        })
        return axios.post(url, formData, {onUploadProgress: onUploadProgress})
    },









    /*

    
    async getMe (attendee_code) {
        return axios.post('/me', {
            code: attendee_code
        }) 
    },
    
    async getStageInfo (stage_code) {
        return axios.post('/enter_stage', {
            stage_code: stage_code
        }) 
    },

    async getSessionInfo (session_code) {
        return axios.post('/enter_session', {
            session: session_code
        })
    },

    async getMeetingInfo (meeting_code) {
        return axios.post('/enter_meeting', {
            meeting: meeting_code
        })
    },

    async getSessions () {
        return axios.get('/sessions', {
            params : {
                event_id:store.state.event_info.all.event_code
            }
        }) 
    },

    async getInAppMessagingConversations () {
        return axios.get('/inapp_messaging/conversations') 
    },

    async getInAppMessagingConversation (id) {
        return axios.get('/inapp_messaging/conversation/' + id) 
    },

    sendChatMessage (message) {
       return axios.post('/chat', {
            message:message, 
            session_id:store.state.session.all.code
        })
    },

    sendQuestion (question) {
        return axios.post('/new_question', {
            question:question, 
            session_id:store.state.session.all.code
        })
    },

    loadQuestions () {
        return axios.get('/session/questions', {
            params : {
                orderby:store.state.session.questions_orderby, 
                session_id:store.state.session.all.code
            }
        })
    },

    loadDocuments () {
        return axios.get('/session_document', {
            params : {
                session_id:store.state.session.all.code
            }
        })
    },

    loadPolls () {
        return axios.get('/survey/list', {
            params : {
                session_id:store.state.session.all.code
            }
        })
    },

    loadChatHistory () {
        return axios.get('/session_chat_history', {
            params : {
                session_id:store.state.session.all.code
            }
        })
    },

    questionReaction(qid) {
        return axios.post('/session/question/reaction', {
            reaction_type:'upvote', 
            qid:qid
        })
    },

    clap () {
        return axios.post('/clap', {
            session_id:store.state.session.all.code
        })
    },

    sendCTA(title,content, button_text, button_link) {
        return axios.post('/moderator/cta/add', {
            new_cta_title: title,
            new_cta_text: content,
            new_cta_button_text:button_text,
            new_cta_button_url:button_link,
            session_id:store.state.session.all.code
        })
    },

    sendPoll(question,options) {
        return axios.post('/moderator/survey/add', {
            question: question,
            options: options,
            session_id:store.state.session.all.code
        })
    },

    async sendInappMessage(message,to) {
        return axios.post('/inapp_messaging/send', {
            message: message.value,
            to: to
        })
    },

    async readConversation(code) {
        return axios.post('/inapp_messaging/read', {
            code: code
        })
    },

    async submitPoll(data) {
        return axios.post('/survey/vote', data)
    },

    async blockAttendeeModerator(attendee_id) {
        return axios.post('/moderator/attendee/block', {
            attendee_id: attendee_id
        })
    },

    async blockAttendee(attendee_id) {
        return axios.post('/attendee/block', {
            attendee_id: attendee_id
        })
    },

    async deleteChatMessage(message_id) {
        return axios.post('/moderator/chat/delete', {
            message_id: message_id
        })
    },

    async moveToBroadcast(content_type,content_id) {
        return axios.post('/moderator/move_to_broadcast', {
            content_type: content_type,
            content_id: content_id
        })
    },

    async deleteQuestion(question_id) {
        return axios.post('/moderator/question/delete', {
            question_id: question_id
        })
    },

    closeLowerThird() {
        return axios.post('/moderator/close_lowerthird_content', {
            session_id:store.state.session.all.code
        })
    },

    raiseHand() {
        return axios.post('/raise_hand', {
            session_id:store.state.session.all.code
        })
    },

    showSpeakerName(speaker_id) {
        return axios.post('/moderator/lower_third', {
            session_id:store.state.session.all.code,
            speaker_id:speaker_id
        })
    },

    approveHandraise(attendee_id) {
        return axios.post('/moderator/hand_raise/approve', {
            attendee_id:attendee_id
        })
    },

    async startSession(type) {
        return axios.post('/moderator/session/start', {
            type: type,
            session_id:store.state.session.all.code
        })
    },

    endSession() {
        return axios.post('/moderator/session/end', {
            session_id:store.state.session.all.code
        })
    },

    async sendSessionFeedback(rating, comment) {
        return axios.post('/feedback', {
            rating: rating,
            comment: comment,
            session_id:store.state.session.all.code
        })
    },

    async getSessionStats() {
        return axios.get('/moderator/session_stats', {
            params : {
                session_id:store.state.session.all.code
            }
        })
    },

    startVideoCall(to,source) {
        return axios.post('/video_call/start', {
            to:to,
            source:source,
        })
    },

    startSpeedNetworking() {
        return axios.post('/networking/start')
    },

    async photoBoothUpload(photo) {
        return axios.post('/photo_booth/upload', {
            photo: photo
        })
    },

    async photoBoothUploadFinal(photo) {
        return axios.post('/photo_booth/upload_final', {
            photo: photo
        })
    },

    async saveNote(type, id, note) {
        return axios.post('/attendee/note', {
            type: type,
            id: id,
            note:note
        })
    },

    async deleteNote(type, id) {
        return axios.post('/attendee/note/delete', {
            type: type,
            id: id
        })
    },

    async favProgram(id) {
        return axios.post('/session/fav', {
            id: id
        })
    },

    async showInterest(expo_id) {
        return axios.post('/expo/interest', {
            expo_id: expo_id
        })
    },

    async setupMeeting(participants, selected_slot, description) {
        return axios.post('/meeting', {
            participants: participants,
            selected_slot: selected_slot,
            description:description
        })
    },

    async updateAttendee(data) {
        return axios.post('/attendee/update', data)
    },
    */
}