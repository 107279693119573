<script setup>
    import {computed,ref,watch,defineAsyncComponent} from 'vue'
    import draggable from 'vuedraggable'
    import { useStore } from 'vuex'
    import form_template_icons from '@/assets/icons/form_template_icons.svg'

    const icons = import.meta.glob(`@/assets/icons/*.svg`)
    console.log(icons)
    const props = defineProps({
        
    })
    
    const store = useStore()

    const form_data = computed(() => store.state.form.active_form)
    const field_templates = computed(() => store.state.content.form_field_templates)
    
    const template_groups = computed(() => store.state.content.form_field_templates.filter(x=>x.sort != null).sort( (a, b) => a.sort>b.sort ? 1 : -1 ).map( x => { return {type:x.group, name:x.group_name} } ) )
    
    const icon = field => {
        return `/assets/icons/${field.type}.svg`
        console.log(icons[`/src/assets/icons/paragraph.svg`]())
        //return icons[`/src/assets/icons/paragraph.svg`]()
        //const a = defineAsyncComponent(() => import(`../../assets/icons/${field.type}.svg`) )
        const a = defineAsyncComponent(() => import(`../../assets/icons/paragraph.svg`) )
        console.log(a)
        return a
    }
</script>

<template>
    <div class="text-xl font-light mb-5 uppercase p-4">Yeni Alan Ekle</div>
    <template v-for="group in template_groups">
        <div class="flex justify-between w-full px-4 mt-2 text-sm font-bold text-left">{{ group.name }}</div>
        <draggable :sort="false" class="grid grid-cols-5 p-4 gap-2" item-key="id" :list="field_templates.filter(field=>field.group == group.type)" :group="{ name: 'form_fields', pull: 'clone', put: false }">
            <template #item="{element}">
                <div class="h-16 text-xs border rounded p-1 hover:bg-gray-100 cursor-pointer flex flex-col items-center bg-white" :data-type="element.type">
                    <svg class="mb-2 mt-2 shrink-0" role="img" style="color: rgb(69, 85, 96); height: 16px; width: 16px;"><use :href="form_template_icons + '#' + element.type"></use></svg>
                    <div class="mt-auto mb-2 text-center">{{element.name}}</div>
                </div>
            </template>
        </draggable>
    </template>
</template>