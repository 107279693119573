<template>
	<div class="py-3 flex items-center" v-if="filter_row_enabled">
		<slot name="header_desc"></slot>
		
	</div>
	<div class="sm:rounded-lg border mb-5">
		<div class="py-4 px-2 bg-gray-100 flex items-center" v-if="search_enabled || over_header_enabled">
			<slot name="filters"><span></span></slot>
			<UIInput type="search" placeholder="Ara..." v-model="filter_query" size="sm" class="ml-auto" v-if="search_enabled"/>
		</div>
		<table class="w-full table-fixed">
			<thead class="bg-gray-50">
				<tr class="break-words">
					<th v-if="multiSelect">
						<div class="flex justify-center items-center">
							<UICheckbox
								:data="{ id: 'field_required', value: 'Alan zorunlu mu?' }"
							/>
						</div>
					</th>
					<th
						v-for="(title, k) in titles"
						:key="k"
						scope="col"
						class="px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
						:class="title.width ?? 'w-full'"
					>
						<UICheckbox
							:data="{ id: 'field_required', value: 'Alan zorunlu mu?' }"
							v-if="title.type == 'select_all'"
						/>
						<div
							v-else
							class="flex items-center"
							:title="title.title"
							:class="title.id && title.id == sort_by ? 'text-calypso' : ''"
						>
							<span class="uppercase truncate">{{ title.title }}</span>
							
							<template v-if="title.sortable">
								<span @click="onSortChange(title.id)" v-tooltip="'Sırala'" class="ml-auto cursor-pointer flex flex-col text-xs leading-3" v-if="sort_by == title.id && sort_order == 'asc'">&#9650;</span>
								<span @click="onSortChange(title.id)" v-tooltip="'Sırala'" class="ml-auto cursor-pointer flex flex-col text-xs leading-3" v-if="sort_by == title.id && sort_order == 'desc'">&#9660;</span>

								<span @click="onSortChange(title.id)" v-tooltip="'Sırala'" class="ml-auto cursor-pointer flex flex-col text-xs leading-3" v-if="sort_by !== title.id && sort_order == 'asc'">&#9651;</span>
								<span @click="onSortChange(title.id)" v-tooltip="'Sırala'" class="ml-auto cursor-pointer flex flex-col text-xs leading-3" v-if="sort_by !== title.id && sort_order == 'desc'">&#9661;</span>
							</template>
							<!--
							<UITooltip
								position="top"
								title="Sırala"
								class="ml-auto cursor-pointer flex flex-col"
								v-if="title.sortable"
								@click="onSortChange(title.id)"
							>
								<span v-tooltip="'Tarih Seç'" class="text-xs leading-3" v-if="sort_by == title.id && sort_order == 'asc'">&#9650;</span>
								<span class="text-xs leading-3" v-if="sort_by == title.id && sort_order == 'desc'">&#9660;</span>

								<span class="text-xs leading-3" v-if="sort_by !== title.id && sort_order == 'asc'">&#9651;</span>
								<span class="text-xs leading-3" v-if="sort_by !== title.id && sort_order == 'desc'">&#9661;</span>
							</UITooltip>
							-->
						</div>
					</th>
				</tr>
			</thead>
			<tbody class="bg-white divide-y divide-gray-200">
				<slot name="rows"></slot>
			</tbody>
			<tfoot>
				<slot name="footer"></slot>
			</tfoot>
		</table>
	</div>
	<Paginator
		v-if="paginator && paginator.last_page > 1"
		:paginator="paginator"
		:data_length="paginator.total"
		@pagechange="onPageChange"
	/>
</template>

<script setup>
	import { ref, watch, useSlots,computed } from "vue"
	import Paginator from "./Paginator.vue"

	const slots = useSlots()
	const emit = defineEmits([
		"onPageChange",
		"onFilterQueryChange",
		"onSortChange",
	])

	const props = defineProps({
		paginator: Object,
		paginated: String,
		titles: Array,
		filter_row_enabled: {
			type: Boolean,
			default: true,
		},
		multiSelect: {
			type: Boolean,
			default: false,
		},
		sort_order: String,
		sort_by: String,
		search_enabled: {
			type: Boolean,
			default: true,
		},
		over_header_enabled: {
			type: Boolean,
			default: false,
		},
		
	})
	
	const filter_query = ref("")
	const sort_by = computed(() => props.sort_by)

	watch(() => filter_query.value, data => {
		emit("onFilterQueryChange", data)
	}, { immediate : true })

	const onSortChange = (data) => {
		emit("onSortChange", data)
	}

	const onPageChange = (data) => {
		emit("onPageChange", data)
	}
</script>
