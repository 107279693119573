
//import MessengerPage from '../pages/Messenger.vue'
//import Forward from '../pages/Forward.vue'

let routes = [
	{
		path: "/approves",
		component: () => import('../modals/Approves.vue'),
        name:'ApprovesPage',
		meta: { 
			requiresAuth: false,
			layout:'standalone'
		}
	},
	{
		path: "/approval-decision/:id/:person/:type",
		component: () => import('../pages/ApprovalDecision.vue'),
        name:'ApprovalDecision',
		meta: { 
			requiresAuth: false,
			layout:'standalone'
		}
	},
	{
		path: "/",
		component: () => import('../pages/Welcome.vue'),
        name:'Welcome',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Merhaba',
			subtitle:'Bugün seni neler bekliyor?'
		}
	},
	{ 
		path: '/tasks', 
		redirect: { name: 'Tasks', params:{type:'list'} } 
	},
    {
		path: "/tasks/:type",
		component: () => import('../pages/Tasks.vue'),
        name:'Tasks',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Yapılacaklar',
			//subtitle:'Yapman gereken işleri organize et'
		}
	},
    {
		path: "/calendar",
		component: () => import('../pages/Calendar.vue'),
        name:'Calendar',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Takvim',
			subtitle:'Ne zaman ne oluyor cevapları burada'
		}
	},
    {
		path: "/reports",
		redirect: '/reports/executive-brief',
		component: () => import('../pages/Reports.vue'),
        name:'Reports',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Raporlar',
			subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
		},
		children: [
			{
			  	path: 'executive-brief',
			  	component: () => import('../pages/reports/ExecutiveBrief.vue'),
				meta: { 
					requiresAuth: true,
					layout:'normal',
					title:'Yönetici Özeti',
					subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
				}
			},
			{
				path: 'performance',
				component: () => import('../pages/reports/Performance.vue'),
				meta: { 
					requiresAuth: true,
					layout:'normal',
					title:'Performans Raporu',
					subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
				}
			},
			{
				path: 'division-performance',
				component: () => import('../pages/reports/DivisionPerformance.vue'),
				meta: { 
					requiresAuth: true,
					layout:'normal',
					title:'Departmen Raporu',
					subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
				}
			},
			{
				path: 'people-performance',
				component: () => import('../pages/reports/PeoplePerformance.vue'),
				meta: { 
					requiresAuth: true,
					layout:'normal',
					title:'Ekip Performans Raporu',
					subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
				}
			},
			{
				path: 'clients',
				component: () => import('../pages/reports/Clients.vue'),
				meta: { 
					requiresAuth: true,
					layout:'normal',
					title:'Müşteri Raporu',
					subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
				}
			},
			{
				path: 'yoy',
				component: () => import('../pages/reports/YOY.vue'),
				meta: { 
					requiresAuth: true,
					layout:'normal',
					title:'YoY Ciro Raporu',
					subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
				}
			},
			{
				path: 'suppliers',
				component: () => import('../pages/reports/Suppliers.vue'),
				meta: { 
					requiresAuth: true,
					layout:'normal',
					title:'Tedarikçi Raporu',
					subtitle:'İşlerin durumunu anlamanın en hızlı yolu'
				}
			},
			{
				path: 'unclosed-projects',
				component: () => import('../pages/reports/UnclosedProjects.vue'),
			},
			{
				path: 'client-financials',
				component: () => import('../pages/reports/ClientFinancials.vue'),
			},
			{
				path: 'invoicing-timeframes',
				component: () => import('../pages/reports/InvoicingTimeframes.vue'),
			}
		]
	},
    {
		path: "/clients",
		component: () => import('../pages/Clients.vue'),
        name:'Clients',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Müşteriler',
			subtitle:'',
			image:''
		}
	},
	{
		path: "/client/:code",
		component: () => import('../pages/ClientDetail.vue'),
        name:'ClientDetail',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Müşteri Profili',
			subtitle:'İşine yarayacak tüm bilgiler burada',
			breads:[
				{
					route_name:'Clients',
					title:"Müşteriler",
				}
			]
		}
	},
	{
		path: "/suppliers",
		component: () => import('../pages/Suppliers.vue'),
        name:'Suppliers',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Tedarikçiler',
			subtitle:'Oteller, Mekanlar, Restoranlar, İkram, Ekip ve Diğer Herşey',
			
		}
	},
	{
		path: "/supplier/:code",
		component: () => import('../pages/SupplierDetail.vue'),
        name:'SupplierDetail',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Tedarikçi Profili',
			subtitle:'İşine yarayacak tüm bilgiler burada',
			breads:[
				{
					route_name:'Suppliers',
					title:"Tedarikçiler",
				}
			]
		}
	},
	{
		path: "/contacts",
		component: () => import('../pages/Contacts.vue'),
        name:'Contacts',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Kontaktlar',
			subtitle:'Müşteri ve tedarikçi kontaklarını burada bulabilirsin'
		}
	},
	{
		path: "/bizdev",
		component: () => import('../pages/Bizdev.vue'),
        name:'Bizdev',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Teklif Yönetimi',
			subtitle:'Yeni iş fırsatlarını takip et ve yönet'
		}
	},
	{
		path: "/bizdev/:code",
		component: () => import('../pages/BizdevDetail.vue'),
        name:'BizdevDetail',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Teklif Yönetimi',
			subtitle:'Tüm yeni iş bağlantılarını yönet',
			custom_header_bg_color:true,
			breads:[
				{
					route_name:'Bizdev',
					title:"Teklif Yönetimi",
				}
			]
		}
	},
	{
		path: '/bizdev/:code/brain-storming/:template_id',
		name: "BrainStormingDetail",
		meta: { 
			requiresAuth: true,
			requiresProjectData: true,
			layout:'standalone',
			title:'Brainstorming'
		},
		component: () => import('../pages/bizdev_detail/BrainStormingDetail.vue')
	},
	{
		path: '/project/:code/sponsors/:scode',
		name: "SponsorDetail",
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'İş Geliştirme',
			subtitle:'Tüm yeni iş bağlantılarını yönet',
			breads:[
				{
					route_name:'Bizdev',
					title:"İş Geliştirme",
				}
			]
		},
		component: () => import('../pages/project_detail/SponsorDetail.vue')
	},
	{
		path: "/depot",
		component: () => import('../pages/Depot.vue'),
        name:'Depot',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Depo',
			subtitle:'Etkinliklerde kullanacağın malzemeleri rezerve et'
		}
	},
	{
		path: "/accounting",
		component: () => import('../pages/Accounting.vue'),
        name:'Accounting',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Muhasebe',
			subtitle:'Muhasebe verilerini burada görüntüleyebilirsin'
		}
	},
	{
		path: "/profile",
		component: () => import('../pages/Profile.vue'),
        name:'Profile',
		meta: { 
			requiresAuth: true,
			layout:'normal',
			title:'Profil',
			subtitle:'Kendinle ilgili herşeyi bu ekrandan ayarlayabilirsin'
		}
	},
	{
		path: '/onboarding',
		component: () => import('../pages/Onboarding.vue'),
		name: "Onboarding",
		meta: { 
			requiresAuth: true,
			layout:'standalone',
			title:'Hoşgeldin'
		}
	},
	{
		path: '/messenger',
		component: () => import('../pages/Messenger.vue'),
		name: "Messenger",
		meta: { 
			requiresAuth: true,
			layout:'standalone',
			title:'Hoşgeldin'
		}
	},
	/*{
		path: '/channel',
		component: () => import('../pages/channel/Channel.vue'),
		name: "Channel",
		meta: { 
			requiresAuth: true,
			layout:'standalone',
			title:'Hoşgeldin'
		}
	},*/
	{
		path: '/login',
		component: () => import('../pages/auth/Login.vue'),
		name: "Login",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Giriş'
		}
	},
	{
		path: '/forgot-password',
		component: () => import('../pages/auth/ForgotPassword.vue'),
		name: "ForgotPassword",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Şifremi Unuttum'
		}
	},
	{
		path: '/password-reset/:token',
		component: () => import('../pages/auth/PasswordReset.vue'),
		name: "PasswordReset",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Şifreni Sıfırla'
		}
	},
	{
		path: '/web-form/:slug/:pcode?',
		component: () => import('../pages/WebForm.vue'),
		name: "WebForm",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'WebForm'
		}
	},
	{
		path: '/post-event-survey-form/:code',
		component: () => import('../pages/PostEventSurveyForm.vue'),
		name: "PostEventSurveyForm",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Post Event Survey Form'
		}
	},{
		path: '/manuel-rsvp/:token/login',
		component: () => import('../pages/manuel_rsvp/auth/Login.vue'),
		name: "ManuelRSVPLogin",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Manuel LCV Giriş'
		}
	},
	{
		path: '/manuel-rsvp/form',
		component: () => import('../pages/manuel_rsvp/Form.vue'),
		name: "ManuelRSVPForm",
		meta: { 
			requiresAuth: true,
			layout:'standalone',
			title:'Manuel LCV Form'
		}
	},
	{
		path: '/manuel-rsvp/form/:code',
		component: () => import('../pages/manuel_rsvp/FormDetail.vue'),
		name: "ManuelRSVPFormDetail",
		meta: { 
			requiresAuth: true,
			layout:'standalone',
			title:'Manuel LCV Form Detayı'
		}
	},
	{
		path: '/client-review/:code',
		component: () => import('../pages/client_review/Detail.vue'),
		name: "ClientReview",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Müşteri Bütçe İnceleme'
		}
	},
	{
		path: '/sponsor-review/:code',
		component: () => import('../pages/sponsor_review/Detail.vue'),
		name: "SponsorReview",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Sponsor'
		}
	},
	{
		path: '/refeees-review/:code',
		component: () => import('../pages/judges_review/Detail.vue'),
		name: "JudgeReview",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Hakem'
		}
	},
	{
		path: '/checkin/:code',
		component: () => import('../pages/checkin/Checkin.vue'),
		name: "Checkin",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Checkin'
		}
	},
	{
		path: '/decleration/:code',
		component: () => import('../pages/decleration/Record.vue'),
		name: "Decleration",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Bildiri'
		}
	},
	{
		path: '/:slug/:ccode?',
		component: () => import('../pages/CustomerForm.vue'),
		name: "CustomerForm",
		meta: { 
			requiresAuth: false,
			layout:'standalone',
			title:'Customer Form'
		}
	}


	
];


export default {
    routes
}