<script setup>
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
    
    import { computed,onMounted,ref,watch,toRef } from 'vue'
    import { onClickOutside } from '@vueuse/core'
    import { ChevronDownIcon, CheckIcon, XIcon } from '@heroicons/vue/solid'
    
    const props = defineProps({
        modelValue:[String,Number,Array,Object],
        title: String,
        title_color : {
            type : String,
            default : 'text-calypso'
        },
        label: String,
        help_text: String,
        id : String,
        source: Array,
        errors:Array,
        display : {
            type : String,
            default : 'inline'
        },
        max_display : Number,
        allow_create:{
            type:Boolean,
            default:false
        },
        multiple:{
            type:Boolean,
            default:false
        },
        tags:{
            type:Boolean,
            default:false
        },
        search_enabled:{
            type:Boolean,
            default:true
        },
        use_arrow:{
            type:Boolean,
            default:true
        },
        only_icon:{
            type:Boolean,
            default:false
        },
        tag_border:{
            type:Boolean,
            default:true
        },
        icons_stacked:{
            type:Boolean,
            default:false
        },
        icon_tooltip:{
            type:Boolean,
            default:false
        },
        resettable:{
            type:Boolean,
            default:false
        },
        styled:{
            type:Boolean,
            default:true
        },
        disabled:{
            type:Boolean,
            default:false
        },
        disabled_title:String,
        size:{
            type:String,
            default:'normal'
        },
        prepended:{
            type:Boolean,
            default:false
        },
        tag_bg_color:String,
        add_option: String,
        pos_h:String,
        options_line_break:{
            type:Boolean,
            default:false
        },
        fit_parent:{
            type:Boolean,
            default:false
        }
    })

    const create_label = option => {
        let label = option.text
        if(option.subtext) {
            label = label + '<div class="text-xs text-gray-500">' + option.subtext + '</div>'
        }
        return label
    }

    const error = computed(() => props.errors && props.errors.length > 0)
    //const checked_items = ref(props.modelValue)
    const checked_items = ref([])
    const emit = defineEmits(['update:modelValue','focus','add_option','change'])

    const options_wrapper = ref(null)
    const rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))
    const popover_button = ref(null)
    const popover_left_margin = ref(0)
    const select_wrapper = ref(null)
    const help_text = ref(props.help_text)
    const open = ref(false)
    const popover_v_position = ref('down')
    const popover_h_position = ref('left')
    const search_input_up_rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))
    const search_input_down_rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))

    onMounted(() => {
        onClickOutside(select_wrapper.value, () => {
            open.value = false
            filter_keyword.value = null
        })
    })

    const trigger_add_option = () => {
        emit('add_option')
    }
    

    const set_checked_items = () => {
        if(props.modelValue == null) return
        if(typeof props.modelValue == 'object') {
            props.modelValue.forEach(item => {
                checked_items.value.push(props.source.find(x => x.value == item ))
            })
        } else if(typeof props.modelValue == 'string' || typeof props.modelValue == 'number') {
            checked_items.value.push(props.source.find( x => x.value == props.modelValue ))
        }
    }

    const reset = () => {
        checked_items.value = []
        emit('update:modelValue', null)
        emit('change',null)
        //set_checked_items()
    }
    
    watch(() => props.modelValue, data => {
        //if(!data) return
        checked_items.value = []
        set_checked_items()	
	},{ immediate: true })

    watch(() => props.source, data => {
        if(!data) return
        checked_items.value = []
        set_checked_items()
        	
	},{ immediate: true })

    watch(() => open.value, data => {
        emit('focus',data)
        
        if(data) {
            setTimeout(() => {
                if(document.getElementsByClassName("selected_option").length > 0) {
                    document.getElementsByClassName("selected_option")[0].scrollIntoView({behavior: "smooth",block: "nearest", inline: "nearest"})
                }
            }, 100)
        }
	},{ immediate: true })
    
    const onchange = data => {
        if(data.checked) {
            //checked_items.value.push(data.value)
            checked_items.value.push(props.source.find(x=>x.value == data.value))
        } else {
            checked_items.value = checked_items.value.filter(item => item.value !== data.value)
        }
        
        emit('update:modelValue', checked_items.value.map(item => item.value))
        emit('change',data)
    }

    const select = (data,close) => {
        if(!data) {
            checked_items.value = []
            emit('update:modelValue',data)
            emit('change',data)
            //close()
            open.value = false
            return    
        }
        checked_items.value = [props.source.find(x=>x.value == data)]
        emit('update:modelValue',data)
        emit('change',data)
        //close()
        open.value = false
    }

    const filter_keyword = ref(null)
    const new_option = ref('')

    const data_source = computed(() => {
        if(!props.source) return true
        return props.source.filter(x=> {
            if(!filter_keyword.value) return true
            return x.text.toLocaleLowerCase().includes(filter_keyword.value.toLocaleLowerCase()) || (x.subtext && x.subtext.toLocaleLowerCase().includes(filter_keyword.value.toLocaleLowerCase())) 
        })
    })

    
    const add_new_option = (close) => {
        new_option.value = filter_keyword
        //close()
        open.value = false
    }

    const calculate_position = () => {
        if(!popover_button.value || popover_button.value.el == null) return
        
        if(popover_button.value.el.getBoundingClientRect().width < 25) {
            popover_left_margin.value = 25 - (popover_button.value.el.getBoundingClientRect().width / 2)
        }
		const distance_to_bottom = window.innerHeight - popover_button.value.el.getBoundingClientRect().y
        popover_v_position.value = distance_to_bottom < 400 ? 'up' : 'down'

        const distance_to_right = window.innerWidth - popover_button.value.el.getBoundingClientRect().x
        popover_h_position.value = props.pos_h || (distance_to_right < 400 ? 'right' : 'left')
        
	}

    const toggle_popover = () => {
    
        if(props.disabled) return
        
        calculate_position()	
		open.value = !open.value
        
        if(props.search_enabled && open.value) {
            setTimeout(()=> {
                const up = document.getElementById(search_input_up_rand_id.value)
                const down = document.getElementById(search_input_down_rand_id.value)
                if(up) up.focus()
                if(down) down.focus()
            },50)
        }
	}
    
    watch(() => popover_button.value, data => {
        if(!popover_button.value || !popover_button.value.el) return
        setTimeout(calculate_position,200)
	},{ immediate: true })

    
</script>

<template>
    <div
        :class="{
            'w-full h-full' : fit_parent
        }"
    >
        <UILabel :help_text="help_text" :label="label" :rand_id="id || rand_id" :isFocused="open" @click="toggle_popover"/> 
        <Popover class="relative"
            :class="{
                'w-full h-full' : fit_parent
            }"
        >
            <div ref="select_wrapper"
            :class="{
                'w-full h-full' : fit_parent
            }"
            >
                <PopoverButton ref="popover_button" @click="toggle_popover"
                    class="flex items-center ring-2 ring-transparent truncate text-ellipsis max-w-full"
                    :class="{
                        'w-full rounded-iq focus:outline-none focus:ring-calypso border p-2' : display == 'box', // min-h-[42px]
                        'border-gray-300 bg-gray-50' : display == 'box' && !error,
                        'border-red-100 bg-red-50' : display == 'box' && error,
                        'focus:ring-calypso' : display == 'box' && !prepended && !error,
                        'focus:ring-red-600': display == 'box' && !prepended && error,
                        'text-sm' : display == 'inline',
                        'text-xs' : size == 'xs',
                        'text-sm' : styled && display == 'inline',
                        'border-r-0 rounded-r-none' : prepended,
                        'w-full h-full pl-2 outline-0' : fit_parent
                    },[props.title_color]">
                        <div class="flex" :class="{'flex-wrap': multiple}" v-if="checked_items.length > 0 && tags">
                            <span v-for="checked_item,index in checked_items" :key="checked_item" 
                                class="flex items-center" 
                                :class="{
                                    'p-1 text-xs' : multiple,
                                    'border-slate-200 border rounded my-1 mr-1' : multiple && tag_border,
                                    'bg-white' : multiple && display == 'box'
                                }"
                                :style="'background-color:' + tag_bg_color"
                            >
                                <div class="w-5 rounded-full overflow-hidden" v-if="checked_item && checked_item.image"
                                :class="{
                                    'mr-1' : !only_icon,
                                    '-ml-3' : index > 0 && icons_stacked,
                                }"
                                >
                                    <UITooltip :title="checked_item.text" position="right" v-if="icon_tooltip">
                                        <img :src="checked_item.image">
                                    </UITooltip>
                                    <img :src="checked_item.image" v-else />
                                </div>
                                <span class="truncate font-normal text-gray-600" v-if="!only_icon && checked_item">{{checked_item.text}}</span>
                            </span>
                        </div>
                        <span v-if="disabled && disabled_title" class="text-gray-500">{{ disabled_title || title }}</span>
                        <span v-if="!disabled && checked_items.length == 0" class="text-gray-500">{{ title }}</span> 
                        <span v-if="!disabled && checked_items.length > 0 && multiple && !tags">{{ title }} <span class="mx-1 bg-calypso text-white px-1 rounded text-xs">{{ checked_items.length }}</span></span> 
                        <span v-if="!disabled && checked_items.length > 0 && checked_items[0] && !multiple && !tags" class="flex items-center"><img :src="checked_items[0].image" class="w-6 h-6 rounded-full mr-2" v-if="checked_items[0].image"/>{{ checked_items[0].text }}</span> 
                        <ChevronDownIcon v-if="use_arrow && !disabled" class="shrink-0 w-4 h-4 text-calypso duration-300" :class="{'ml-auto':display == 'box', 'transform rotate-180':open}" /> 
                        <XIcon class="shrink-0 w-4 h-4 text-calypso duration-300" @click.stop="reset" v-if="resettable && checked_items.length > 0 && !multiple && !tags"/>
                </PopoverButton>
                <div class="validation_error" v-if="error">{{errors[0].$message}}</div>
            
                <transition
                    enter-active-class="transition duration-200 ease-out"
                    enter-from-class="translate-y-1 opacity-0"
                    enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="transition duration-150 ease-in"
                    leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="translate-y-1 opacity-0"
                    v-if="open"
                >
                    
                    <PopoverPanel
                        class="absolute z-10 shadow bg-white border border-gray-iq shadow-iq rounded-iq  max-w-md" v-slot="{ close }"
                        :class="{
                            'min-w-max' : !options_line_break,
                            'min-w-fit' : options_line_break,
                            'w-full' : display == 'box',
                            'mt-2' : display == 'inline',
                            '-translate-y-full top-0' : popover_v_position == 'up',
                            '!-top-5' : popover_v_position == 'up' && display == 'inline',
                            'right-0' : popover_h_position == 'right',
                            'left-5' : popover_h_position == 'left' && display == 'inline',
                            '-ml-6' : popover_left_margin < 30 && popover_h_position == 'left' && display == 'inline',
                            '-mr-6' : popover_left_margin < 30 && popover_h_position == 'right' && display == 'inline'
                        }"
                        static
                    >
                        <div v-if="display == 'inline' && popover_v_position == 'down'" 
                            class="-top-2 w-4 h-4 absolute bg-white border-r border-b border-gray-iq -rotate-135 transform"
                            :class="{
                                'right-5' : popover_h_position == 'right',
                                'left-5' : popover_h_position == 'left',
                            }"
                        ></div>
                        <div class="py-2 pl-4 border-t" v-if="props.add_option&& popover_v_position == 'up'">
                            <span class="text-calypso hover:underline cursor-pointer text-sm font-bold select-none" @click="trigger_add_option">{{ add_option }}</span>
                        </div>
                        <div class="bg-white px-4 py-2 border-b border-gray-iq relative rounded-t-iq" v-if="search_enabled && popover_v_position == 'down'">
                            <UIInput :id="search_input_down_rand_id" :styled="false" size="sm" v-model="filter_keyword" placeholder="Ara..."/>
                        </div>
                        <div class="max-h-80 overflow-y-auto overflow-x-hidden" ref="options_wrapper">
                            <UIList v-if="data_source.length > 0 && multiple">
                                <UICheckbox v-for="item,k in data_source" :key="k" :inputValue="item.value" :label="create_label(item)" @change="onchange" :checked="checked_items.filter(x => x.value == item.value.toString()).length > 0" class="hover:bg-teal pl-3 pr-10 py-2"/>
                            </UIList>
                            <UIList v-if="data_source.length > 0 && !multiple">
                                <!--
                                <li @click="select(null,close)" class="hover:bg-teal duration-500 pr-10 py-2 pl-4 pr-10 text-sm flex items-center relative cursor-pointer">
                                    {{title}}
                                    <span
                                        v-if="checked_items.length == 0"
                                        class="absolute inset-y-0 right-2 flex items-center pl-3 text-amber-600"
                                    >
                                        <CheckIcon class="w-5 h-5 text-calypso" aria-hidden="true" />
                                    </span>
                                </li>
                                -->
                                <li v-for="item,k in data_source" :key="k" :data="item" @click="select(item.value,close)" class="hover:bg-teal duration-500 pr-10 py-2 pl-4 pr-10 text-sm flex items-center relative cursor-pointer">
                                    <div class="w-8 rounded-full overflow-hidden mr-4" v-if="item.image">
                                        <img :src="item.image">
                                    </div>
                                    <div>
                                        <div>{{item.text}}</div>
                                        <div v-if="item.subtext" class="text-xs text-slate-500">{{item.subtext}}</div>
                                    </div>
                                    <span
                                        v-if="checked_items[0] && checked_items[0].value == item.value.toString()"
                                        class="absolute inset-y-0 right-2 flex items-center pl-3 text-amber-600 selected_option"
                                    >
                                        <CheckIcon class="w-5 h-5 text-calypso" aria-hidden="true" />
                                    </span>
                                </li>
                            </UIList>
                            
                            <div v-if="data_source.length == 0 && !allow_create" class="py-5 text-center text-sm">Hiç sonuç bulunamadı</div>
                            <div v-if="data_source.length == 0 && allow_create" class="hover:bg-teal duration-500 pr-10 py-2 pl-4 pr-10 text-sm flex items-center relative cursor-pointer" @click="add_new_option(close)">Yeni ekle : {{ filter_keyword }} </div>
                        </div>
                        <div class="py-2 pl-4 border-t" v-if="props.add_option&& popover_v_position == 'down'">
                            <span class="text-calypso hover:underline cursor-pointer text-sm font-bold select-none" @click="trigger_add_option">{{ add_option }}</span>
                        </div>
                        <div class="bg-white px-4 py-2 border-t border-gray-iq relative rounded-b-iq" v-if="search_enabled && popover_v_position == 'up'">
                            <UIInput :id="search_input_up_rand_id" :styled="false" size="sm" v-model="filter_keyword" placeholder="Ara..."/>
                        </div>
                        <div v-if="display == 'inline' && popover_v_position == 'up'" 
                            class="-bottom-2 w-4 h-4 absolute bg-white border-l border-t border-gray-iq -rotate-135 transform -z-1"
                            :class="{
                                'right-5' : popover_h_position == 'right',
                                'left-5' : popover_h_position == 'left',
                            }"
                        ></div>
                    </PopoverPanel>
                </transition>
            </div>
        </Popover>
    </div>
</template>
