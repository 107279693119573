<template>
    <UIPageTitle title="Kontacklar"/>
	<UITable :titles="table_titles">
		<template v-slot:rows>
			<tr v-if="contacts.length == 0" class="h-96 "><td colspan="6" class="text-center"><h1>Aradığın kriterlerde bir kontak yok görünüyor</h1></td></tr>
			<tr v-for="contact in contacts" :key="contact.id" class="hover:bg-teal cursor-pointer">
				<UITableCell>
					<div class="font-bold truncate">{{ contact.name }}</div>
				</UITableCell>
                <UITableCell>
					<div class="flex gap-2" v-for="similar in contact.data">
                        <span class="truncate">{{ similar.name }}</span>
                        <span class="text-green-300">({{ similar.percent }} %)</span>
                    </div>
				</UITableCell>
                <UITableCell>
					<div class="font-bold truncate"> <i class="fa fa-merge ml-2 border p-1" @click="open_merge_modal(contact.id)"></i></div>
				</UITableCell>
			</tr>
		</template>
	</UITable>

    <FormModal 
		key="merge"
		actionButtonText='Birleştir'
		:isSubmitting="isSubmitting" 
		:modalProps="mergeModalProps" 
		:isOpen="isMergeModalOpen" 
		@submit="submit_merge" 
		@modal_closed="close_merge_modal"
	/>
</template>

<script setup>
	import { ref,computed,inject } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import API from '@/api'
	import TableView from '@/views/TableView.vue'
    import FormModal from '@/modals/Form.vue'
    
    const store = useStore()
	const emitter = inject('emitter')

    const isSubmitting = ref(false)
    const isMergeModalOpen = ref(false)


    const selected_contact = ref(null)

    const contacts= ref([])
    const similar_contacts= ref([])
	const isModalOpen = ref(false)

	const empty_state = {
        title:'Benzer kontak listesinde eşleşen veri bulunamadı!',
        description:''
	}

    let table_titles = [
		{
			title:'Tedarikçi Adı',
		},
        {
			title:'Benzer Veriler',
		},
        {
			title:'',
		}
	]

	const modal_closed =() => {
		isModalOpen.value = false
    }

    const getContacts = () =>{
        API.get('similarity/contacts').then(response=>{
            contacts.value=response.data.filter(x=>x.is_similarity)
            console.log(contacts.value);
        })
    }

    getContacts()

    const open_merge_modal = id => {
        
        let contactIndex=contacts.value.findIndex(x=>x.id==id)
        if (contactIndex>=0) {
           
            selected_contact.value = id
            similar_contacts.value=contacts.value[contactIndex].data.map(x=>{return {value:x.id,text:x.name}})
        

            isMergeModalOpen.value=true
        }
        
    }

    const close_merge_modal = () => {
        isMergeModalOpen.value = false
        isSubmitting.value = false
    }

    const submit_merge = data => {
        isSubmitting.value = true
        data.merge_from = selected_contact.value
       
        API.put('merge/contacts', data).then(response => {
			close_merge_modal()
            isSubmitting.value = false
            contacts.value=contacts.value.filter(x=>x.id!=data.merge_from && x.id!=data.merge_to)
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
				title:'BAŞARILI!',
				description:response.data.message
			})
		}).catch(error => {
			
            isSubmitting.value = false
            store.dispatch('floating_alerts/addAlert', {
				type:'tip',
				title:'HATA!',
				description:error.response.data.message
			})
		})
	}

    const mergeModalProps = computed(() => {return {
		title:'Kontakları Birleştir',
		cols:1,
		header:{
			type:'tip',
			text:'Bu kontağa ait tüm bilgiler aşağıda seçeceğin kontağa aktarılacaktır.',
			title:'Bilgi'
		},
		schema: [
			{
				id: 'merge_to',
				component: 'UISelect',
				label: 'Hangi kontak ile birleştirmek istiyorsun?',
				required: true,
				props:{
					title:'Kontak seç',
					source:similar_contacts.value,
					display:'box'
				}
			}
		]
	}})

</script>
