<script setup>
    import { computed, ref, watch ,onBeforeMount, onMounted} from "vue"
    import draggable from 'vuedraggable'


    const isFormFiedlOptionModalOpen = ref(false)

    const onSpecChange = (e) => {
		field.value[e.value] = e.checked ? 1 : 0
	}
    const add_contracts = () => {

        API.post(
            "form/" + form_data.value.code + "/field/" + field.value.id + "/contracts_option",new_option.value)
        .then((response) => {
            store.dispatch("form/updateField", {
                id: field.value.id,
                field: response.data.data,
            })
            field.value = response.data.data
            store.dispatch("floating_alerts/addAlert", {
                type: "tip",
                title: "BAŞARILI!",
                description: "Seçenek eklendi.",
            })
            new_option.value = {}
        })
        .catch((error) => {
            store.dispatch("floating_alerts/addAlert", {
                type: "danger",
                title: "HATA!",
                description: error.response.data.message,
            })
        })
    }

    const form_field_option_text = (option) =>{
		isFormFiedlOptionModalOpen.value = true;
		selected_option.value = option
	}

	

	const ck_editor_set = () =>{
		setTimeout(() => {
       
		ClassicEditor
			.create( editor.value, {
				toolbar: ['bold', 'italic', 'link','uploadImage','|','numberedList', 'bulletedList']
			} )
			.then(data => {
				console.log(data)
				/*
				editor = data

				editor.model.document.on('change:data', () => {
					console.log(editor.getData());
					new_option.value.text = editor.getData()
				});
				*/
				
			})
			.catch( error => {
				console.error( error );
			} );
		}, 2000);
	}

	onMounted(() => {
		//ck_editor_set()
	})
        
</script>

<template>
    <div class="flex gap-3 text-xs font-medium text-gray-500 uppercase mb-1">
				<div class="grow">Sözleşme Adı</div>
				<div class="w-16">Dosya</div>
				<div class="w-12"></div>
			</div>
			<draggable 
				@sort="()=>{}"
				group="form_fields" 
				item-key="id"
				v-model="field_options" 
				ghost-class="ghost"
				drag-class="bg-white"
				animation=300
			> 
				<template #item="{element}">
					<UIFormRow  :data-option-id="element.id"  :key="element.id" class="flex gap-3" >
						<UIInput
							placeholder="Sözleşme Adı"
							class="grow"
							:modelValue.lazy="element.option_text"
							@change="event => update_option(event.target.value,element.id,'option_text')"
						/>
						<UIFormRow v-if="element.contract_type=='file'" class="flex gap-2">
							<a class="text-sky-400 flex text-center m-auto" :href="element.file_full" target="_blank"> Sözleşme <i class="fa fa-external-link text-xs ml-2 mt-1"></i></a>
						</UIFormRow>

						<UIFormRow v-if="element.contract_type=='text'" class="flex ml-2">
							<div class="text-sky-400 flex text-center m-auto" @click="form_field_option_text(element)">
								Sözleşme <i class="fa fa-file text-xs ml-1 mt-1"></i>
							</div>
							<!-- <UIPopover position="right" @open="() => {}" @close="() => {}">
								<template v-slot:title>
									<div class="text-sky-400 flex text-center m-auto">
										Sözleşme <i class="fa fa-file text-xs ml-1 mt-1"></i>
									</div>
								</template>
								<template v-slot:content>
									<div class="flex" v-html="element.text"></div>
								</template>
							</UIPopover> -->
						</UIFormRow>
						
						<UIButton
							class="w-12 items-center"
							type="danger"
							text="Sil"
							@click="delete_option(element.id)"
						/>
					</UIFormRow>
				</template>
			</draggable>
			<!-- <UIFormRow v-for="option in field.options" :key="option.id" class="flex gap-3 hidden" >
				<UIInput
					placeholder="Sözleşme Adı"
					class="grow"
					:modelValue.lazy="option.option_text"
					@change="event => update_option(event.target.value,option.id,'option_text')"
				/>
				<UIFormRow class="flex gap-2">
					<a class="text-sky-400 flex text-center m-auto" :href="option.file_full" target="_blank"> Sözleşme <i class="fa fa-external-link text-xs ml-2 mt-1"></i></a>
				</UIFormRow>
				
				<UIButton
					class="w-12 items-center"
					type="danger"
					text="Sil"
					@click="delete_option(option.id)"
				/>
			</UIFormRow> -->
			<hr class="my-5" />
			<UIFormRow class="flex gap-2">
				<UIInput
					placeholder="Sözleşme Adı"
					class="grow"
					v-model="new_option.option_text"
				/>

				<UISelect :source="[{value:'file',text:'Dosya Yükle'},{value:'text',text:'Metin Gir'}]" title="Tip" display="box" v-model="new_option.contract_type"/>
				
				<UIButton
					class="w-24 h-10 items-center"
					type="submit"
					text="Ekle"
					@click="add_contracts"
				/>
			</UIFormRow>
			<UIFormRow class="" v-if="new_option.contract_type=='file'">
				<UIFileInput
					class="grow"
					v-model="new_option.file"
				/>
			</UIFormRow>

			<UIFormRow class="gap-2" :class="{
				'hidden':new_option.contract_type!='text'
			}" >
				<div ref="editor" v-html="new_option.text"></div>
				<!-- <UITextarea :autogrow="false" class="h-40 bg-transparent text-gray-500"
					placeholder="Sözleşme metnini giriniz..." 
					v-model="new_option.text" 
				/> -->
			</UIFormRow>
			<UIFormRow>
				<UICheckbox
						label="Seçenekleri yan yana listele"
						inputValue="direction"
						:checked="field.direction == 1"
						class="mb-1"
						@change="onSpecChange"
					/>
			</UIFormRow>    
</template>