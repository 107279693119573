<template>
    <div>
        <UILabel :label="label" :help_text="help_text" :rand_id="id || rand_id" :isFocused="isFocused"/>
        <div class="relative flex items-center self-center" :class="isFocused && styled ? 'ring-2 ring-calypso rounded-iq' :''">
            <UISelect v-if="currency" :search_enabled="false" :prepended="true" class="w-16 grow-0 shrink-0" :source="currencies" title="Seç" display="box" v-model="selected_currency"/>
            <input 
                @focus="on_focus"
                @blur="on_blur"
                @keyup="calculate_width"
                type="text" 
                class="focus:outline-none focus:border-transparent"
                :class="{ 
                    'focus:ring-red-600': error && styled, 
                    'focus:ring-calypso': !error && styled,
                    'rounded-iq border border-gray-300 bg-gray-50' : styled,
                    'p-2' : size == 'normal',
                    'p-1 text-sm' : size == 'sm',
                    'p-0' : size == 'xs',
                    'focus:ring-transparent bg-transparent' : !styled,
                    'w-full' : !autoexpand,
                    'border-l-0 rounded-l-none' : currency,
                    'text-right' : text_align == 'right'
                }"
                autocomplete="off"
                :disabled="disabled"
                ref="inputRef"
                :id="id || rand_id"
            >
        </div>
    </div>
</template>

<script setup>
    import {computed,ref,watch} from 'vue'
    import { useStore } from 'vuex'
    import { useCurrencyInput } from 'vue-currency-input'
    
    const props = defineProps({
        id:String,
        modelValue: [String,Number],
        error:Boolean,
        label:String,
        help_text:String,
        styled:{
            type:Boolean,
            default:true
        },
        autoexpand:{
            type:Boolean,
            default:false
        },
        size:{
            type:String,
            default:'normal'
        },
        options: Object,
        precision:{
            type:Object,
            default:{ min: 2, max: 2 }
        },
        disabled:{
            type:Boolean,
            default:false
        },
        text_align: {
            type : String,
            default : 'right'
        },
        currency : String
    })

    const emit = defineEmits(['update:currency','update:modelValue','blur','focus'])

    const store = useStore()
    const currencies = computed(() => store.getters['content/currencies_select_sign'])
    const width = ref(0)
    const selected_currency = ref(props.currency)
    const help_text = ref(props.help_text)
    const isFocused = ref(false)
    let rand_id = computed(()=>'currency_input_' + (Math.floor(Math.random() * 1000000000)))
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)

    const calculate_width = e => {
        if(!props.autoexpand) return
        let width = (inputRef.value.value.includes(',') ? ( inputRef.value.value.length * 11) : (inputRef.value.value.length) * 9) + 'px'
        inputRef.value.style.width = width
    }
    
    watch(() => inputRef, input => {
        if(inputRef.value && inputRef.value.value & props.autoexpand) {
            calculate_width()
        }
    },{ immediate: true,deep:true })

    
    watch(() => selected_currency.value, value => emit('update:currency', value))
    watch(() => props.modelValue, value => setValue(value))
    watch(() => props.options, options => setOptions({...options, ...{ distractionFree: false, useGrouping: true, precision: props.precision}}))

    const on_blur = () => {
        isFocused.value = false
        emit('blur')
    }

    const on_focus = () => {
        isFocused.value = true
        emit('focus')
    }

    defineExpose({inputRef})

</script>
